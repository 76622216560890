import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Checkbox, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import PopoverNav from '../../../components/PopoverNav';
import { DragHandle } from './DragHandle';
import { useDashboard } from './useDashboard';

export const ViewSelector = ({ storageKey, onViewToggled, onCustomOrderChanged, }) => {
    const { views = [], dashboardKey, setOpenConfirmDelete, setOpenAddNew, } = useDashboard();
    const navigate = useNavigate();
    const [sortMode, setSortMode] = useState('recent');
    const [recentlyUsed, setRecentlyUsed] = useState([]);
    const [hiddenViews, setHiddenViews] = useState([]);
    const [sortedViewIds, setSortedViewIds] = useState(views.map((view) => view.id));
    const params = useParams();
    const viewId = params.id;

    const updateLocalStorage = useCallback((key, value) => {
        localStorage.setItem(storageKey, JSON.stringify({
            ...JSON.parse(localStorage.getItem(storageKey)),
            [key]: value,
        }));
    }, [storageKey]);

    useEffect(() => {
        if (viewId) {
            setRecentlyUsed((prev) => {
                const newRecent = prev.filter((id) => id !== viewId);
                newRecent.unshift(viewId);
                updateLocalStorage('recent', newRecent);
                return newRecent;
            });
        }
    }, [viewId, storageKey, updateLocalStorage]);

    useLayoutEffect(() => {
        const savedState = JSON.parse(localStorage.getItem(storageKey));
        if (savedState) {
            setRecentlyUsed(savedState.recent ?? []);
            setHiddenViews(savedState.hidden ?? []);
            setSortedViewIds(savedState.custom ?? views.map((view) => view.id));
        }
    }, [storageKey, views]);

    function handleAddNewClick() {
        navigate(`../add-new`, { relative: 'path' });
        setOpenAddNew(true);
    }

    function handleEditClick() {
        setOpenAddNew(true);
    }

    function handleDeleteClick() {
        setOpenConfirmDelete(true);
    }

    function handleViewSelect(view) {
        setViewVisibility(view.id, true);
        navigate(`../${view.id}`, { relative: 'path' });
        localStorage.setItem(`${dashboardKey}-last-view-id`, view.id);
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;
        // If we drag outside of any valid drop zone, 'over' can be null
        if (!over) return;

        // If the active item is dropped over a different item
        if (active.id !== over.id) {
            setSortedViewIds((prevItems) => {
                const oldIndex = prevItems.indexOf(active.id);
                const newIndex = prevItems.indexOf(over.id);
                const newOrder = arrayMove(prevItems, oldIndex, newIndex);
                updateLocalStorage('custom', newOrder);
                onCustomOrderChanged(newOrder);
                return newOrder;
            });
        }
    };

    const sortedViews = useMemo(() => {
        if (sortMode === 'abc') {
            return views.toSorted((a, b) => a.label.localeCompare(b.label));
        } else if (sortMode === 'recent') {
            return views.toSorted((a, b) => {
                const aIndex = recentlyUsed.indexOf(a.id.toString());
                const bIndex = recentlyUsed.indexOf(b.id.toString());
                if (aIndex === -1) {
                    return 1;
                } else if (bIndex === -1) {
                    return -1;
                }
                return aIndex - bIndex;
            });
        } else if (sortMode === 'custom') {
            const sorted = views.toSorted((a, b) => {
                const aIndex = sortedViewIds.indexOf(a.id);
                const bIndex = sortedViewIds.indexOf(b.id);
                return aIndex - bIndex;
            });
            return sorted;
        } else {
            return views;
        }
    }, [views, sortMode, recentlyUsed, sortedViewIds]);

    const setViewVisibility = (id, visible) => {
        setHiddenViews((prev) => {
            if (visible) {
                const newHidden = prev.filter((vId) => vId !== id);
                updateLocalStorage('hidden', newHidden);
                return newHidden;
            } else {
                const newHidden = [...new Set([...prev, id])];
                updateLocalStorage('hidden', newHidden);
                return newHidden;
            }
        });
        onViewToggled(id);
    }

    const viewIsVisible = (view) => !hiddenViews.includes(view.id);

    const handleToggleItemVisible = (event, view) => {
        event.stopPropagation();
        setViewVisibility(view.id, !viewIsVisible(view));
    }

    return (
        <PopoverNav
            icon={<MoreVertIcon />}
            closeOnLocationChange={false}
            popoverProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }
            }}
        >
            <Stack direction='row' spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: 1, }}>
                <Typography>Sort:</Typography>&nbsp;&nbsp;
                <ToggleButtonGroup
                    exclusive
                    value={sortMode}
                    size="small"
                    color="primary"
                    sx={{ mt: .2, mr: 1.3, display: 'contents' }}
                    onChange={(_, newMode) => {
                        newMode && setSortMode(newMode)
                    }}
                >
                    <ToggleButton sx={{ mt: .5 }} value={'abc'}>
                        ABC
                    </ToggleButton>
                    <ToggleButton sx={{ mt: .5 }} value={'recent'}>
                        Recent
                    </ToggleButton>
                    <ToggleButton sx={{ mt: .5 }} value={'custom'}>
                        Custom
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Divider />
            <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
            >
                <SortableContext items={sortedViewIds} strategy={verticalListSortingStrategy}>
                    <List >
                        {
                            sortedViews.map((view) => (
                                <SortableItem
                                    key={`${dashboardKey}-dashboard-view-item-${view.id}`}
                                    id={view.id}
                                    text={view.label}
                                    onViewSelect={() => handleViewSelect(view)}
                                    onToggleVisible={(event) => handleToggleItemVisible(event, view)}
                                    checked={!hiddenViews.includes(view.id)}
                                    checkbox
                                    disabled={hiddenViews.length === views.length - 1 && !hiddenViews.includes(view.id)} // Prevent hiding all views
                                    allowDrag={sortMode === 'custom'}
                                />
                            ))
                        }
                    </List>
                </SortableContext>
            </DndContext>
            < Divider />
            <List dense>
                <Item text="Add New" onClick={handleAddNewClick} icon={<AddIcon />} />
                <Item text="Edit Current" onClick={handleEditClick} icon={<EditIcon />} />
                <Item text="Delete Current" onClick={handleDeleteClick} icon={<RemoveCircleOutlineIcon />} />
            </List>
        </PopoverNav >
    );
}

const Item = ({ icon, text, onClick, }) => {
    return (
        <ListItem disablePadding>
            <ListItemButton dense onMouseDown={onClick}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={text} />
            </ListItemButton>
        </ListItem>
    );
};

const SortableItem = ({ id, text, onViewSelect, onToggleVisible, checked, checkbox, disabled, allowDrag }) => {
    const theme = useTheme();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id, disabled: !allowDrag, });

    const dragBackgroundColor = theme.palette.mode === 'dark' ? '#333' : '#f0f0f0';

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
        backgroundColor: isDragging ? dragBackgroundColor : '',
    };

    return (
        <ListItem
            disablePadding
            ref={setNodeRef}
            style={style}
        >
            <ListItemButton dense onClick={onViewSelect}>
                {allowDrag && <DragHandle listeners={listeners} attributes={attributes} isDragging={isDragging} />}
                {checkbox && <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={checked}
                        onClick={onToggleVisible}
                        tabIndex={-1}
                        disableRipple
                        sx={{ py: 0, }}
                        disabled={disabled}
                    />
                </ListItemIcon>}
                <ListItemText primary={text} />
            </ListItemButton>
        </ListItem>
    );
}
