import { Checkbox, FormControlLabel, Stack, useTheme } from "@mui/material";
import { EditDealButton, CreateBookoutButton, FlagScheduleButton, } from "../RibbonButtons";

export const HourlyToolbar = ({ pivot, handlePivotChange, getBookoutData, selectedData, onFlagStatusChanged, }) => {
    const theme = useTheme();
    const toFlag = !selectedData || selectedData.Schedule_Status === 'CONFIRMED';

    return (
        <Stack
            direction="row"
            spacing={4}
            sx={{
                paddingRight: theme.spacing(4),
                display: 'flex',
                alignItems: 'center',
            }}
        >

            <FormControlLabel
                control={
                    <Checkbox
                        checked={pivot}
                        onClick={handlePivotChange}
                    />
                }
                label="Pivot Hours"
            />

            <CreateBookoutButton
                getBookoutData={getBookoutData}
                selectedRow={selectedData}
            />

            <FlagScheduleButton
                scheduleId={selectedData?.scheduleID}
                toFlag={toFlag}
                onFlagStatusChanged={onFlagStatusChanged}
            />

            <EditDealButton
                disabled={!selectedData}
                deal={selectedData}
                label={'Edit Deal'}
            />
        </Stack>
    );
};
