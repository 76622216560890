import { useLayoutFunctions } from "../useLayoutFunctions"
import { useState, useCallback, useLayoutEffect } from "react";
import { unionBy, } from 'lodash'

export const useMultipleLayouts = (storageKey, ref, baseColDefs, colDefArray, defaultColDef, defaultLayout = {}) => {
    const [colDefs, setColDefs] = useState([]);
    const { applyLayout, applyFilters, captureLayout, } = useLayoutFunctions();

    const resetLayout = useCallback(() => {
        const newDefs = applyLayout(defaultLayout, baseColDefs, defaultColDef);
        setColDefs(newDefs);

        if (defaultLayout.filters) {
            applyFilters(ref, defaultLayout.filters);
        }
    }, [defaultLayout, baseColDefs, defaultColDef, applyLayout, applyFilters, ref,]);

    const loadFilters = useCallback(() => {
        const localLayout = localStorage.getItem(storageKey);
        if (localLayout) {
            const layout = JSON.parse(localLayout);
            if (layout.filters) {
                applyFilters(ref, layout.filters);
            }
        } else if (defaultLayout.filters) {
            applyFilters(ref, defaultLayout.filters);
        }
    }, [defaultLayout, applyFilters, ref, storageKey]);

    const loadLayout = useCallback(() => {
        const localLayout = localStorage.getItem(storageKey);
        if (localLayout) {
            const layout = JSON.parse(localLayout);
            const newDefs = applyLayout(layout, baseColDefs, defaultColDef);
            setColDefs(newDefs);
        } else if (defaultLayout.layout) {
            const newDefs = applyLayout(defaultLayout.layout, baseColDefs, defaultColDef);
            setColDefs(newDefs);
        }

        loadFilters();
    }, [defaultLayout, applyLayout, storageKey, baseColDefs, defaultColDef, loadFilters]);

    const saveLayoutLocal = useCallback(() => {
        const layout = captureLayout(ref);
        const savedLayoutStr = localStorage.getItem(storageKey);
        const savedLayout = JSON.parse(savedLayoutStr ?? '{}');

        const iteratee = (def) => def.colId || def.field;
        const colDefUnion = colDefArray.reduce((acc, curr) => unionBy(acc, curr, iteratee), []);
        const withOldLayout = unionBy(savedLayout.state, colDefUnion, iteratee);
        const withLayout = unionBy(layout.state, withOldLayout, iteratee);

        localStorage.setItem(storageKey, JSON.stringify({ ...layout, state: withLayout, }));
    }, [ref, storageKey, captureLayout, colDefArray]);

    const deleteLayoutLocal = useCallback(() => {
        resetLayout();
        localStorage.removeItem(storageKey);
    }, [resetLayout, storageKey]);

    useLayoutEffect(() => {
        if (ref.current.api && colDefs.length) {
            const currentLayout = captureLayout(ref);
            saveLayoutLocal();
            const savedLayoutStr = localStorage.getItem(storageKey) ?? '{}';
            const savedLayout = JSON.parse(savedLayoutStr);
            const iteratee = (def) => def.colId || def.field;
            const layout = unionBy(currentLayout.state, savedLayout.state, iteratee);
            const newDefs = applyLayout({ state: layout, }, baseColDefs, defaultColDef);
            setColDefs(newDefs);
        }
    }, [baseColDefs, defaultColDef]);

    //save layout when window unloads
    useLayoutEffect(() => {
        window.addEventListener('beforeunload', saveLayoutLocal)
        return () => {
            window.removeEventListener('beforeunload', saveLayoutLocal)
        }
    }, [saveLayoutLocal]);

    return {
        colDefs,
        loadLayout,
        loadFilters,
        saveLayoutLocal,
        deleteLayoutLocal,
        resetLayout,
    };
}
