import { useImperativeHandle, useState, forwardRef, useRef, useCallback, useMemo } from "react"
import { Box, } from "@mui/material"
import { useChart } from "../useChart"
import { TagReportRibbon } from "./Ribbon/TagReportRibbon"
import { ViewContextProvider } from "../ViewContextProvider"
import * as yup from 'yup';
import dayjs from "dayjs"
import { useApi } from "../../useApi";
import { useDashboardFetch } from "../useDashboardFetch";
import { useLayoutFunctions } from "../../useLayoutFunctions"
import { TagReportGrid } from "./TagReportGrid"
import { isLongDay, diffData } from "../Utils"
import { useUserInfo } from "../../UserInfoContext"

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    startDate: yup.date().required('Start Date is required'),
    stopDate: yup.date().required('Stop Date is required'),
});

export const TagReportView = forwardRef(({ view, }, ref) => {
    const { ChartContainer, createChartContainer, popupParent, } = useChart({ id: view.id });
    const [showChart, setShowChart] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedAdjustment, setSelectedAdjustment] = useState();
    const gridRef = useRef();
    const layoutStorageKey = `deal-rizz-tag-report-grid-layout-${view.id}`;
    const toolbarFormId = `tag-report-toolbar-form-${view.id}`;
    const { headers, apiUrlPrefix, get } = useApi();
    const { captureLayout } = useLayoutFunctions();
    const userInfo = useUserInfo();

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        source: '',
        onlyShowNonZeroCuts: false,
        ...view,
        excludeMarketPathPSE: view.excludeMarketPathPSE || null,
        startDate: dayjs(),
        stopDate: dayjs(),
    }), [view]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    const getRowId = useCallback((params) => {
        const { data, } = params;
        return `${data.tagIdx}-${data.ProfileType}-${data['tagDate']}`;
    }, []);

    function toggleExtraHourColBasedOnDate({ startDate, stopDate }) {
        let start = dayjs(startDate).startOf('day');
        const end = dayjs(stopDate).startOf('day');
        let longDayInRange = false;

        do {
            const isLong = isLongDay(start);
            if (isLong) {
                longDayInRange = true;
                break;
            }
            start = start.add(1, 'day');
        } while (start.isSameOrBefore(end, 'day'));

        gridRef.current?.columnApi?.setColumnVisible('2*', longDayInRange);
    }

    const applyData = useCallback((response) => {
        //const timestamp = dayjs().format('HH:mm:ss.SSS');
        //console.log(`Tag Report apply data started at ${timestamp}`);
        if (response?.data && gridRef.current) {
            const newData = response?.data ?? [];

            //clear old data
            const oldData = [];
            gridRef.current?.api.forEachLeafNode(node => {
                oldData.push(node.data);
            });

            const { toAdd, toUpdate, toDelete, } = diffData(newData, oldData, (data) => getRowId({ data, }));
            gridRef.current.api.applyTransaction({ add: toAdd, update: toUpdate, remove: toDelete });

            //const timestamp2 = dayjs().format('HH:mm:ss.SSS');
            //console.log(`Tag Report apply data finished at ${timestamp2}`);
            //refresh the cells in the updated rows to keep the cell renderers in sync
            const nodesToRefresh = toUpdate.map(row => gridRef.current.api.getRowNode(getRowId({ data: row })));
            gridRef.current.api.refreshCells({ rowNodes: nodesToRefresh, force: true });
            //const timestamp3 = dayjs().format('HH:mm:ss.SSS');
            //console.log(`Tag Report apply data refresh finished at ${timestamp3}`);
        }
        return response;
    }, [getRowId]);

    const fetchParamKeys = ['userGuid', 'timezone', 'startDate', 'stopDate', 'onlyShowNonZeroCuts', 'excludeMarketPathPSE', 'tagIdx', 'tenantId'];

    const { loadData, fetchData, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_TagReport_FetchSummary_v8',
        fetchParamKeys: fetchParamKeys,
        gridRef,
        getRowId,
    });

    const silentUpdate = useCallback(async (data) => {
        //return fetchData(formatDataForRequest(data)).then(formatDataFromResponse).then(applyData);
        const formatted = formatDataForRequest(data);
        const paramString = fetchParamKeys.reduce((acc, key) => {
            return `${acc}&parm=${formatted[key] ?? ''}`;
        }, '');
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_FetchSummary_v8${paramString}`
        return get(url).then(response => {
            if (!!response?.data?.length && !!gridRef.current) {
                const formatted = formatDataFromResponse(response).data[0];
                const updatedRowId = getRowId({ data: formatted });
                const existingNode = gridRef.current.api.getRowNode(updatedRowId);
                if (existingNode) {
                    //then update the existing row, and refresh the cells
                    gridRef.current.api.applyTransaction({ update: [formatted], });
                    gridRef.current.api.refreshCells({ rowNodes: [existingNode], force: true });
                } else {
                    //then add the new row
                    gridRef.current.api.applyTransaction({ add: [formatted], });
                }
            }
        });
    }, []);

    function handleFetchData(data) {
        //close all expanded groups; will cause any open detail grids to refresh
        gridRef.current.api.collapseAll();
        //logAction('User fetched Tag Report Data', 'Tag Reports', data);
        loadData(formatDataForRequest(data)).then(formatDataFromResponse).then(applyData);
        toggleExtraHourColBasedOnDate(data);
    }

    function formatDataForRequest(data) {
        return {
            userGuid: headers.userGuid,
            timezone: data.timezone,
            startDate: dayjs(data.startDate).format('MM/DD/YYYY'),
            stopDate: dayjs(data.stopDate ?? data.startDate).format('MM/DD/YYYY'),
            onlyShowNonZeroCuts: data.onlyShowNonZeroCuts ? 1 : 0,
            tagIdx: data.tagIdx ?? -1,
            tenantId: userInfo.tenantId,
        };
    }

    function formatDataFromResponse(response) {
        const data = response?.data;
        const formatted = data?.map(row => {
            const newRow = { ...row };
            Array.from({ length: 26 }).forEach((_, i) => {
                const hour = i + 1;
                newRow[hour] = tryParseJson(row[hour]);
            });

            newRow['2*'] = tryParseJson(row['2*']);

            return newRow;
        });
        return { data: formatted };
    };

    function tryParseJson(json) {
        const data = {};
        try {
            return JSON.parse(json);
        } catch (e) {
            console.error(e);
        }
        return data;
    }

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box className='flex-column'>
                <TagReportRibbon
                    toolbarFormId={toolbarFormId}
                    selectedRows={selectedRows}
                    selectedAdjustment={selectedAdjustment}
                    handleRefresh={handleFetchData}
                    gridRef={gridRef}
                    setShowChart={setShowChart}
                    showChart={showChart}
                    silentUpdate={silentUpdate}
                />
                <TagReportGrid
                    ref={gridRef}
                    layoutStorageKey={layoutStorageKey}
                    getRowId={getRowId}
                    setSelectedAdjustment={setSelectedAdjustment}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    silentUpdate={silentUpdate}
                />
                <Box sx={{ display: showChart ? 'flex' : 'none', flex: 1, width: '100%' }} id='tag-report-chart-container'>
                    <ChartContainer style={{ width: '100%' }} />
                </Box>
            </Box>
        </ViewContextProvider>
    )
});
