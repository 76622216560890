import { Stack } from "@mui/material";
import dayjs from "dayjs";
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import * as yup from 'yup';
import { useApi } from "../../useApi";
import { useLayoutFunctions } from "../../useLayoutFunctions";
import { useDashboardFetch } from "../useDashboardFetch";
import { rangeHasLongDate } from "../Utils";
import { ViewContextProvider } from "../ViewContextProvider";
import { ReviewGrid } from "./ReviewGrid";
import { ReviewRibbon } from "./Ribbon/ReviewRibbon";

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    startDate: yup.date().required('Start Date is required'),
    stopDate: yup.date().required('Stop Date is required'),
    counterparty: yup.string().required('Counterparty is required'),
    transaction: yup.string().required('Transaction is required'),
    book: yup.string().required('Book is required'),
});

export const ReviewView = forwardRef(({ view, }, ref) => {
    const gridRef = useRef();
    const toolbarFormId = `review-toolbar-form-${view.id}`;
    const { headers, } = useApi();
    const { captureLayout } = useLayoutFunctions();
    const [filterGroups, setFilterGroups] = useState(!!view.filterGroups);
    const [pivotHours, setPivotHours] = useState(!!view.pivotHours);
    const [selectedData, setSelectedData] = useState();

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        book: 'All',
        ...view,
        startDate: dayjs().startOf('month'),
        stopDate: dayjs(),
    }), [view]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    const getRowId = useCallback((params) => {
        const { data, } = params;
        return `${data.dealID}-${data.scheduleID}-${data['Tag Code']}-${data['Flow Date']}-${data.HE}`;
    }, []);

    const dataFormatter = useCallback((data) => {
        return {
            ...data,
            userGuid: headers.userGuid,
            startDate: dayjs(data.startDate).format('MM/DD/YYYY'),
            stopDate: dayjs(data.stopDate).format('MM/DD/YYYY'),
        };
    }, [headers]);

    const hours = useMemo(() => {
        return Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(`${i}`);
            return acc;
        }, []);
    }, []);

    const isNullOrUndefined = (value) => value === null || value === undefined;

    const flattenData = useCallback((data) => {
        return data.reduce((acc, item) => {
            hours.forEach((hour) => {
                const hourData = item[hour] ?? {};
                const flattenedRowData = {
                    ...item,
                    ...hourData,
                    HE: hour,
                    scheduleMW: hourData.scheduleMW,
                    price: hourData.price,
                    dealMW: hourData.dealMW,
                    Energy: hourData.Energy,
                    tagMW: hourData.tagMW,
                };
                if (!['dealMW', 'scheduleMW', 'price', 'Energy', 'tagMW'].every(key => isNullOrUndefined(hourData[key]))) {
                    acc.push(flattenedRowData);
                }
            });
            return acc;
        }, []);
    }, [hours]);

    const rollUpData = useCallback((data) => {
        const formatted = data.reduce((acc, item) => {
            const key = String(item.dealID) + item.scheduleID + item['Tag Code'] + item['Flow Date'];
            const existing = acc.get(key) ?? {};
            const updated = {
                ...existing,
                ...item,
                [`${item.HE}`]: {
                    scheduleMW: item.scheduleMW,
                    price: item.price,
                    dealMW: item.dealMW,
                    Energy: item.Energy,
                    tagMW: item.tagMW,
                    discrepancy: item.discrepancy,
                },
            };
            acc.set(key, updated);
            return acc;
        }, new Map());

        return Array.from(formatted.values());
    }, []);

    const { loadData, applyData, fetchData, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_fetchHourlyEnergyData_v2',
        fetchParamKeys: ['userGuid', 'counterparty', 'transaction', 'book', 'startDate', 'stopDate', 'timezone'],
        gridRef,
        getRowId,
        dataFormatter,
    });

    const toggleExtraHourColBasedOnDate = useCallback((startDate, endDate) => {
        const colApi = gridRef.current?.columnApi;
        const isLong = rangeHasLongDate(startDate, endDate);
        colApi?.setColumnVisible('2*.scheduleMW', isLong);
        colApi?.setColumnVisible('2*.price', isLong);
        colApi?.setColumnVisible('2*.dealMW', isLong);
        colApi?.setColumnVisible('2*.tagMW', isLong);
        colApi?.setColumnVisible('2*.Energy', isLong);
    }, []);

    const formatAndApplyData = useCallback((data, startDate, stopDate) => {
        const formatted = data.map(item => ({
            ...item,
            'Flow Date': dayjs(item['Flow Date']).format('MM/DD/YYYY'),
        }));

        if (pivotHours) {
            const grouped = rollUpData(formatted);
            applyData({ data: grouped });
        } else {
            applyData({ data: formatted });
        }
        toggleExtraHourColBasedOnDate(startDate, stopDate);
    }, [applyData, pivotHours, rollUpData, toggleExtraHourColBasedOnDate]);

    const silentUpdate = useCallback(async (formData) => {
        return fetchData(formData).then(response => {
            if (response) {
                const responseData = response.data ?? [];
                formatAndApplyData(responseData, formData.startDate, formData.stopDate);
            }
        });
    }, [fetchData, formatAndApplyData]);

    const handleFetchData = useCallback(async (formData) => {
        loadData(formData).then(response => {
            if (response) {
                const responseData = response.data ?? [];
                formatAndApplyData(responseData, formData.startDate, formData.stopDate);
            }
        });
    }, [loadData, formatAndApplyData]);

    const pivotData = useCallback((pivot) => {
        const data = [];
        gridRef.current?.api.forEachLeafNode((node) => {
            data.push(node.data);
        });

        gridRef.current?.api.setRowData([]);
        if (pivot) {
            const grouped = rollUpData(data);
            applyData({ data: grouped });
        } else {
            const flattened = flattenData(data);
            applyData({ data: flattened });
        }

    }, [applyData, rollUpData, flattenData]);

    function handleUpdatePivot() {
        setPivotHours(p => {
            pivotData(!p);
            return !p;
        });
    }

    const toggleMWColumns = useCallback((colKey, show, startDate, stopDate) => {
        const longDayInRange = rangeHasLongDate(startDate, stopDate);

        const hours = Array.from({ length: 26 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(`${i}`);

            if (i === 2 && longDayInRange) {
                acc.push('2*');
            }

            return acc;
        }, []);

        hours.forEach((hour) => { // show/hide all MW columns for each hour; handles pivot mode
            gridRef.current?.columnApi?.setColumnVisible(`${hour}.${colKey}`, show);
        });

        gridRef.current?.columnApi?.setColumnVisible(`${colKey}`, show); //this handles the non-pivot mode
    }, [gridRef]);

    const handleHourlySelectionChanged = useCallback((data) => {
        setSelectedData(data);
    }, []);

    const getBookoutData = useCallback(() => {
        if (!selectedData) {
            return null;
        }

        const dealId = selectedData.dealID;
        const flowDate = selectedData['Flow Date'];

        let rowData = [];
        gridRef.current?.api.forEachLeafNode(node => {
            const nodeData = node.data;
            if (nodeData['Flow Date'] === flowDate && nodeData.dealID === dealId) {
                rowData.push(nodeData);
            }
        });

        if (!pivotHours) {
            //make sure the row data is pivoted for consistency
            rowData = rollUpData(rowData);
        }

        const dealData = hours.reduce((acc, hour) => {
            const rowWithNonNullValue = rowData.find(row => !isNullOrUndefined(row[hour]?.dealMW))
            const dealMW = rowWithNonNullValue ? rowWithNonNullValue[hour]?.dealMW : 0;
            return {
                ...acc,
                [hour]: dealMW,
            };
        }, { id: dealId, dealName: selectedData['Deal Name'], });

        const dealPriceData = hours.reduce((acc, hour) => {
            const rowWithNonNullValue = rowData.find(row => !isNullOrUndefined(row[hour]?.price))
            const dealPrice = rowWithNonNullValue ? rowWithNonNullValue[hour]?.price : 0;
            return {
                ...acc,
                [hour]: dealPrice,
            };
        }, { id: 'Deal Price', });

        const dataWithSchedules = rowData.filter(row => row.scheduleID);

        const scheduleData = dataWithSchedules.map(row => {
            return hours.reduce((acc, hour) => {
                const scheduleMW = row[hour]?.scheduleMW ?? 0;
                return {
                    ...acc,
                    [hour]: scheduleMW,
                };
            }, { id: row.scheduleID, });
        });

        return {
            date: selectedData['Flow Date'],
            deal: dealData,
            price: dealPriceData,
            schedules: scheduleData,
        };
    }, [pivotHours, rollUpData, hours, selectedData]);

    const handleFlagStatusChanged = (scheduleId, toFlag, comments) => {
        //iterate over all grid data and update the Schedule_Status and Schedule_Comments fields for rows with the matching scheduleId
        const toUpdate = [];
        gridRef.current?.api.forEachLeafNode(node => {
            const data = node.data;
            if (data.scheduleID === scheduleId) {
                const newComment = toFlag ? 'FLAGGED: ' + comments : data.Schedule_Comment + ' CONFIRMED: ' + comments;
                const updatedData = {
                    ...data,
                    Schedule_Status: toFlag ? 'FLAGGED' : 'CONFIRMED',
                    Schedule_Comment: newComment,
                };
                toUpdate.push(updatedData);
            }
        });
        gridRef.current?.api.applyTransaction({ update: toUpdate });
    }


    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Stack className='flex-column' spacing={1}>
                <ReviewRibbon
                    toolbarFormId={toolbarFormId}
                    handleRefresh={handleFetchData}
                    filterGroups={filterGroups}
                    setFilterGroups={setFilterGroups}
                    pivotHours={pivotHours}
                    setPivotHours={handleUpdatePivot}
                    toggleMWColumns={toggleMWColumns}
                    getBookoutData={getBookoutData}
                    selectedData={selectedData}
                    onFlagStatusChanged={handleFlagStatusChanged}
                />

                <ReviewGrid
                    ref={gridRef}
                    getRowId={getRowId}
                    filterGroups={filterGroups}
                    pivotHours={pivotHours}
                    toggleExtraHourCol={toggleExtraHourColBasedOnDate}
                    toggleMWColumns={toggleMWColumns}
                    setSelectedData={handleHourlySelectionChanged}
                    silentUpdate={silentUpdate}
                />
            </Stack>
        </ViewContextProvider >
    )
});
