import { Ribbon } from "../../Ribbon";
import { GridActionsGroup } from "./GridActionsGroup";
import { ViewForm } from "./ViewForm";

export const CheckoutRibbon = ({ toolbarFormId, handleRefresh, }) => {
    return (
        <Ribbon>
            {[
                {
                    title: 'Grid',
                    content: <GridActionsGroup
                        formId={toolbarFormId}
                        handleRefresh={handleRefresh}
                    />
                },
                {
                    title: 'Filter',
                    content: <ViewForm
                        onSubmit={handleRefresh}
                        formId={toolbarFormId}
                    />
                },
            ]}
        </Ribbon>
    );
}
