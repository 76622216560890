import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { IconButton, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormContext } from "react-hook-form";
import FormAutocomplete from "../../../FormControls/FormAutocomplete";
import FormDatePicker from "../../../FormControls/FormDatePicker";
import { RibbonGroupContainer } from "../../Ribbon";
import { RepublishViewButton } from "../../RibbonButtons/RepublishViewButton";

export const ViewForm = ({ onSubmit, formId, expanded }) => {
    const { handleSubmit, control, getValues, setValue, formState: { errors, }, } = useFormContext();
    /*const [bookOptions, setBookOptions] = useState([]);

    useEffect(() => {
        fetchBookOptions();
    }, []);

    async function fetchBookOptions() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchBookName`

        return axios.get(url, { headers: headers }).then(response => {
            const allBooks = response?.data?.map(obj => obj.book) ?? [];
            const parsed = allBooks.reduce((acc, val) => {
                //this is a bit of a mess, but the values can be in a few different formats, don't ask why
                //values may be comma separated lists, like 'MyBook,MyOtherBook'
                //they may also be stringified arrays, like'[{"label":"MyBook","percentage":"100"}]' 
                //try to parse, if it fails, split
                let parsedVal;
                try {
                    parsedVal = JSON.parse(val)?.map(b => b.label);
                } catch (e) {
                    parsedVal = val.split(',').map(b => b.trim());
                }
                return [...acc, ...parsedVal];
            }, []);
            setBookOptions([...new Set(parsed)]); //remove duplicates
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        })
    }*/

    function handleIncrementDate(days) {
        const tradeDate = getValues('date');
        setValue('date', tradeDate.add(days, 'day'));
    }

    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <RibbonGroupContainer>

                <RepublishViewButton expanded={expanded} />

                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDate(-1);
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <FormDatePicker
                            name='date'
                            control={control}
                            label="Flow Date"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.date,
                                    helperText: errors.date?.message,
                                    sx: { minWidth: '140px', maxWidth: '150px', },
                                }
                            }}
                        />
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDate(1);
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </LocalizationProvider>
                </div>

                <FormAutocomplete
                    autoHighlight
                    options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                    getOptionLabel={(option) => option.split(' ')[0]}
                    control={control}
                    name='timezone'
                    disableClearable
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Prevailing Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                            sx={{ minWidth: '170px', }}
                        />
                    )}
                />

                {/* <FormAutocomplete
                    autoHighlight
                    options={timeTypes}
                    getOptionLabel={(option) => {
                        return option.charAt(0).toUpperCase() + option.slice(1);
                    }}
                    control={control}
                    name='timeType'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Time Type"
                            size='small'
                            color="success"
                            sx={{ minWidth: '150px', }}
                        />
                    )}
                /> */}

                {/* <FormAutocomplete
                    autoHighlight
                    options={bookOptions}
                    control={control}
                    name='book'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Book"
                            size='small'
                            color="success"
                            sx={{ minWidth: '250px', }}
                        />
                    )}
                /> */}

            </RibbonGroupContainer>
        </form>
    );
}
