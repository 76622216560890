import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UndoIcon from '@mui/icons-material/Undo';
import CheckIcon from '@mui/icons-material/Check';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Tooltip } from '@mui/material';
import { RibbonButton, RibbonGroupContainer } from '../../Ribbon';
import { enqueueSnackbar } from 'notistack';
import { useActionAudit } from '../../../useActionAudit';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useApi } from '../../../useApi';
import { TagTerminationDialog } from '../TagTerminationDialog';
import ConfirmationDialog from "../../../TSRActivity/ConfirmationDialog";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PublishIcon from '@mui/icons-material/Publish';
import debounce from 'lodash/debounce';
import { useFormContext } from 'react-hook-form';
import { ResupplyTagButton } from '../../RibbonButtons/ResupplyTagButton';
import SaveIcon from '@mui/icons-material/Save';
import { ConfirmSubmitChangesDialog } from './ConfirmSubmitChangesDialog';
import BalanceIcon from '@mui/icons-material/Balance';
import { useUserGroups } from '../../../../data/useUserGroups';
import { userGroups } from '../../../../authConfig';
import { useUserInfo } from "../../../UserInfoContext"

export const TagActionsRibbonGroup = ({ selectedTags, expanded, gridRef, selectedAdjustment, refreshGrid }) => {
    const [openTerminationDialog, setOpenTerminationDialog] = useState(false);
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({});
    const [openSubmitChangesDialog, setOpenSubmitChangesDialog] = useState(false);
    const selectedTag = selectedTags?.[0];
    const compositeState = selectedTag?.compositeState?.toLowerCase();
    //const profileType = selectedTag?.ProfileType?.toLowerCase();
    const id = selectedTag?.tagIdx;
    const tagCode = selectedTag?.tagCode;
    const tagDate = selectedTag?.tagDate;
    const { logAction } = useActionAudit();
    const { post, headers, apiUrlPrefix, get } = useApi();
    const { watch, handleSubmit } = useFormContext();
    const timezone = watch('timezone');
    const startDate = watch('startDate');
    const stopDate = watch('stopDate');
    const onlyShowNonZeroCuts = watch('onlyShowNonZeroCuts');
    const excludeMarketPathPSE = watch('excludeMarketPathPSE');

    const { userIsInGroup } = useUserGroups();
    const userIsTenantAdmin = userIsInGroup(userGroups.tenantAdmin);
    const enableCorrect = ['pending'].includes(compositeState);
    const enableWithdraw = ['pending'].includes(compositeState);
    //const enableAdjust = ['confirmed', 'implemented'].includes(compositeState) && ['current'].includes(profileType);
    const enableTerminate = ['confirmed', 'implemented'].includes(compositeState);
    //const enableCancel = ['confirmed', 'implemented'].includes(compositeState);
    const enableResupply = selectedTag?.tagStatus?.toLowerCase() === 'curtailed';
    const allowClone = userIsTenantAdmin && !!selectedTag;
    //const allowRemoveDrafts = !!id && !!selectedAdjustment?.RequestId && selectedAdjustment?.RequestStatus?.toLowerCase() === 'draft';
    const allowRemoveDraftTag = userIsTenantAdmin && !!id && selectedTag?.tagStatus?.toLowerCase() === 'draft';
    const tagWasEdited = !!selectedTag?.hasEdits;
    const allowSubmitDraftTag = userIsTenantAdmin && !!id && selectedTag?.tagStatus?.toLowerCase() === 'draft';
    const allowDiscardChanges = tagWasEdited;
    const allowSubmitChanges = tagWasEdited;
    const allowMatchCurtailment = !!id;
    const buttonDebounce = 1500;
    const userInfo = useUserInfo();

    const handleCancel = useCallback(debounce(async () => {
        logAction(`User cancelled tag with id ${id}`, 'Tag Reports', selectedTag);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_ETag_Cancel`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`

        return get(url).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Tag cancelled successfully', { variant: 'success' });
            }
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id, selectedTag]);

    const handleWithdraw = useCallback(debounce(async () => {
        logAction(`User withdrew tag with id ${id}`, 'Tag Reports', selectedTag);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_ETag_Withdraw`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`

        return get(url).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Tag withdrawn successfully', { variant: 'success' });
            }
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id, selectedTag]);

    const handleTerminate = useCallback(debounce(async ({ endDate, notes }) => {
        setOpenTerminationDialog(false);
        logAction(`User terminated tag with id ${id}`, 'Tag Reports', selectedTag);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_ETag_Terminate`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`
            + `&parm=${dayjs(endDate).format('YYYY-MM-DD HH:mm:ss')}`
            + `&parm=${timezone}`
            + `&parm=${notes}`

        return get(url).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Tag terminated successfully', { variant: 'success' });
            }
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id, selectedTag, timezone]);

    const handleSaveAdjustments = useCallback(debounce(async () => {
        logAction(`User adjusted tag with id ${id}`, 'Tag Reports', selectedTag);
        const response = await fetchTransmissionProfiles();
        const adjustmentInfo = response.data;

        const info = {
            transmissionInfo: adjustmentInfo,
            energyInfo: selectedTag,
            timezone: timezone,
        }

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_TagReport_SubmitTagEdits`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`

        return post(url, JSON.stringify(info)).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Tag adjusted successfully', { variant: 'success' });
            }
        });
    }, buttonDebounce, { leading: true, }), [selectedTag, timezone, headers, apiUrlPrefix]);

    async function fetchTransmissionProfiles() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_transAllocForTag_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate).format('MM/DD/YYYY')}`
            + `&parm=${selectedTag.tagIdx}`;

        return get(url);
    }

    const handleSubmitDraft = useCallback(debounce(async () => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_SubmitDraftTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`

        return get(url).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Draft tag submitted successfully.', { variant: 'success' });
                logAction(`User submitted draft tag with id ${id}`, 'Tag Report');
            }
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id]);

    const handleRemoveDrafts = useCallback(debounce(async () => {
        setConfirmationDialogProps({ open: false });
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_RemoveDraftAdjustment`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`
            + `&parm=${selectedAdjustment.RequestId}`

        return get(url).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Draft adjustment removed successfully.', { variant: 'success' });
                logAction(`User removed draft adjustment with requestId ${selectedAdjustment.RequestId} from tag ${id}`, 'Tag Report');
            }
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id, selectedAdjustment]);

    const handleDiscardChanges = useCallback(debounce(async () => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_RemoveEnergyAndTxEdits`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`

        return get(url).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Changes undone successfully.', { variant: 'success' });
                logAction(`User undid tag changes to tag ${id}`, 'Tag Report');
            }
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id]);

    function handleConfirmRemoveDraftAdjustment() {
        setConfirmationDialogProps({
            open: true,
            message: `You are about to remove the selected draft adjustment for the selected tag (${id}). Continue?`,
            onConfirmation: handleRemoveDrafts,
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    function handleConfirmRemoveDraftTag() {
        setConfirmationDialogProps({
            open: true,
            message: `You are about to remove the selected draft tag (${id}). Continue?`,
            onConfirmation: handleRemoveDraftTag,
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    function handleConfirmSubmitDraftTag() {
        setConfirmationDialogProps({
            open: true,
            message: `You are about to submit the selected draft tag (${id}). Continue?`,
            onConfirmation: handleSubmitDraft,
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    const handleRemoveDraftTag = useCallback(debounce(async () => {
        setConfirmationDialogProps({ open: false });
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_RemoveDraftTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`

        return get(url).then(response => {
            if (response.status === 200) {
                const nodesToRemove = [];
                gridRef.current.api.forEachNode((node) => {
                    if (node.data.tagIdx === id) {
                        nodesToRemove.push(node.data);
                    }
                });
                gridRef.current.api.applyTransaction({
                    remove: nodesToRemove,
                });
                enqueueSnackbar('Draft tag removed successfully.', { variant: 'success' });
                logAction(`User removed draft tag with id ${id}`, 'Tag Report');
            }
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id]);

    const handleCloneTag = useCallback(debounce(async () => {
        setConfirmationDialogProps({ open: false });
        logAction(`User cloned tag with id ${id}`, 'Tag Reports', selectedTag);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=dealrizz.UI_ETag_CloneTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`
            + `&parm=${timezone}`

        return post(url, selectedTag).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Tag cloned successfully. Fetching data for new tag...', { variant: 'success' });
                response?.data && fetchNewTagData(response.data);
            }
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id, selectedTag, timezone]);

    async function fetchNewTagData(tagIdx) {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_FetchSummary_v8`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate ?? startDate).format('MM/DD/YYYY')}`
            + `&parm=${onlyShowNonZeroCuts ? 1 : 0}`
            + `&parm=${excludeMarketPathPSE ?? ''}`
            + `&parm=${tagIdx}`
            + `&parm=${userInfo.tenantId}`; //userInfo?.tenantId?.toString()

        return get(url).then(response => {
            gridRef.current.api.applyTransaction({
                add: response.data ?? [],
                addIndex: 0,
            });
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        });
    }

    async function handleConfirmSubmitChanges() {
        setOpenSubmitChangesDialog(true);
    }

    async function handleSubmitChanges(notes) {
        setOpenSubmitChangesDialog(false);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_SubmitTagEdits`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`
            + `&parm=${notes}`;

        return get(url).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Changes submitted successfully.', { variant: 'success' });
            }
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        });
    }

    async function handleMatchCurtailment() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_MatchCurtailment`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`
            + `&parm=${timezone}`
            + `&parm=${tagDate}`

        return get(url).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Tag adjusted to match curtailment successfully.', { variant: 'success' });
            }
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        });
    }

    return (
        <RibbonGroupContainer sx={{ alignItems: 'start' }} spacing={1}>
            <TagTerminationDialog
                open={openTerminationDialog}
                onTerminate={handleTerminate}
                onCancel={() => setOpenTerminationDialog(false)}
                selectedTag={selectedTag}
            />

            <ConfirmationDialog {...confirmationDialogProps} />

            <ConfirmSubmitChangesDialog
                tagId={tagCode}
                open={openSubmitChangesDialog}
                onConfirmation={handleSubmitChanges}
                onCancel={() => setOpenSubmitChangesDialog(false)}
            />

            {/*<RibbonButton
                label="New"
                expanded={expanded}
                //onMouseDown={handleNewTag}
                icon={<AddCircleOutlineIcon
                    fontSize='small'
                />}
            />*/}

            <Tooltip title="Create a clone of the selected tag.">
                <span>
                    <RibbonButton
                        label="Clone"
                        expanded={expanded}
                        disabled={!allowClone}
                        onMouseDown={handleCloneTag}
                        icon={<ContentCopyIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            {/* <Tooltip title="Adjust the selected tag.">
                <span>
                    <RibbonButton
                        label="Adjust"
                        expanded={expanded}
                        disabled={!enableAdjust}
                        onMouseDown={handleSaveAdjustments}
                        icon={<EditIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip> */}

            <Tooltip title="Submit the selected draft tag.">
                <span>
                    <RibbonButton
                        label="Submit Draft"
                        expanded={expanded}
                        disabled={!allowSubmitDraftTag}
                        onMouseDown={handleConfirmSubmitDraftTag}
                        icon={<PublishIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            {/*<Tooltip title="Remove the selected draft adjustment.">
                <span>
                    <RibbonButton
                        label="Remove Draft Adjustment"
                        expanded={expanded}
                        disabled={!allowRemoveDrafts}
                        onMouseDown={handleConfirmRemoveDraftAdjustment}
                        icon={<DeleteOutlineIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>*/}

            <Tooltip title="Remove the selected draft tag.">
                <span>
                    <RibbonButton
                        label="Remove Draft Tag"
                        expanded={expanded}
                        disabled={!allowRemoveDraftTag}
                        onMouseDown={handleConfirmRemoveDraftTag}
                        icon={<DeleteOutlineIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Discard tag edit(s) for the selected row.">
                <span>
                    <RibbonButton
                        label="Discard Changes"
                        expanded={expanded}
                        disabled={!allowDiscardChanges}
                        onMouseDown={handleDiscardChanges}
                        icon={<DeleteOutlineIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Submit selected row's tag edit(s).">
                <span>
                    <RibbonButton
                        label="Submit Changes"
                        expanded={expanded}
                        disabled={!allowSubmitChanges}
                        onMouseDown={handleConfirmSubmitChanges}
                        icon={<SaveIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Adjust tag market level to match reliability level for the selected row.  Only next hour forward will be adjusted.">
                <span>
                    <RibbonButton
                        label="Match Curtailment"
                        expanded={expanded}
                        disabled={!allowMatchCurtailment}
                        onMouseDown={handleMatchCurtailment}
                        icon={<BalanceIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Withdraw the selected tag.">
                <span>
                    <RibbonButton
                        label="Withdraw"
                        expanded={expanded}
                        onClick={handleWithdraw}
                        disabled={!enableWithdraw}
                        icon={<UndoIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Correct the selected tag.">
                <span>
                    <RibbonButton
                        label="Correct"
                        expanded={expanded}
                        disabled={!enableCorrect}
                        icon={<CheckIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            {/*<RibbonButton
                label="Cancel"
                expanded={expanded}
                onClick={handleCancel}
                disabled={!enableCancel}
                icon={<HighlightOffIcon
                    fontSize='small'
                />}
            />*/}

            <Tooltip title="Terminate the selected tag.">
                <span>
                    <RibbonButton
                        label="Terminate"
                        expanded={expanded}
                        onClick={() => setOpenTerminationDialog(true)}
                        disabled={!enableTerminate}
                        icon={<NotInterestedIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <ResupplyTagButton
                expanded={expanded}
                disabled={!enableResupply}
                selectedTag={selectedTag}
            />

        </RibbonGroupContainer>
    )
};
