import CloseIcon from '@mui/icons-material/Close';
import { useTheme, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { apiUrlPrefix } from "../../authConfig";
import { useApi } from "../useApi";
import useHeader from "../useHeader";

export const FlagScheduleDialog = ({ open, closeDialog, scheduleId, toFlag, onFlagStatusChanged, }) => {
    const { enqueueSnackbar, logAction, post } = useApi();
    const headers = useHeader();
    const theme = useTheme();
    const [comments, setComments] = useState('');

    async function handleFlagSchedule(e) {
        closeDialog(e); // close the dialog and clear selections

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_flagSchedule`
            + `&parm=${headers.userGuid}`
            + `&parm=${scheduleId ?? ''}`
            + `&parm=${toFlag ? 1 : 0}`

        return post(url, comments).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Changes saved successfully.', { variant: 'success' });
                logAction(`User ${toFlag ? 'flagged' : 'unflagged'} schedule.`, 'Deal Rizz Checkout', { scheduleId, toFlag, comments });
                onFlagStatusChanged(scheduleId, toFlag, comments);
                setComments('');
            }
        });
    }

    const titleText = toFlag ? `Flag Schedule ${scheduleId}` : `Unflag Schedule ${scheduleId}`;
    const messageText = toFlag ? 'You are about to flag the selected schedule for review. Continue?' : 'You are about to unflag the selected schedule. Continue?';

    return (
        <Dialog
            open={open}
            maxWidth='md'
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{titleText}</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={closeDialog} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ pb: theme.spacing(2) }}>
                    {messageText}
                </DialogContentText>
                <TextField
                    label="Comments"
                    placeholder="Enter comments here (optional)"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    autoFocus
                    fullWidth
                    multiline
                    maxRows={5}
                />
                <DialogActions sx={{ px: 0, pt: theme.spacing(2) }}>
                    <Button
                        size="small"
                        color="error"
                        onClick={closeDialog}
                    >
                        Cancel
                    </Button>
                    <Box flexGrow={1} />
                    <Button
                        size="small"
                        color="primary"
                        variant='contained'
                        onClick={handleFlagSchedule}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
