import CloseIcon from '@mui/icons-material/Close';
import { TextField, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { useCallback, useMemo, useRef, useState, } from "react";
import { apiUrlPrefix } from "../../authConfig";
import { AgGridContainer } from "../AgGrid/AgGridContainer";
import { defaultColumnDef, defaultGridOptions } from "../AgGrid/defaultGridProps";
import { useApi } from "../useApi";
import useHeader from "../useHeader";
import { isLongDay } from "./Utils";
import { useLookupValues } from './DealEntry/LookupContext';
import { useFormContext } from 'react-hook-form';

export const CreateBookoutDialog = ({ open, closeDialog, bookoutData, }) => {
    const contentContainerRef = useRef(null);
    const theme = useTheme();
    const { enqueueSnackbar, logAction, post, get, } = useApi();
    const headers = useHeader();
    const gridRef = useRef();
    const [index, setIndex] = useState(null);
    const { lookupValues } = useLookupValues();
    const { getValues } = useFormContext();

    const indexOptions = useMemo(() => {
        const indices = lookupValues.filter(lookup => lookup.lookupField === 'Index').map(lookup => lookup.value);
        return [...new Set(indices)];
    }, [lookupValues]);

    const profileHours = useMemo(() => {
        return Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(i);
            if (i === 2 && isLongDay(bookoutData?.date)) acc.push('2*');
            return acc;
        }, []);
    }, [bookoutData]);

    async function insertUpdateSchedule(data) {
        closeDialog(); // close the dialog and clear selections
        const { scheduleID, comments, source, sink, por, pod, timezone, } = data;
        const profileInfo = formatProfileInfo(data);
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_scheduleInsertUpdate_v6`
            + `&parm=${headers.userGuid}`
            + `&parm=${scheduleID ?? ''}`
            //+ `&parm=${selectedRows.purchase.dealID ?? ''}`
            //+ `&parm=${selectedRows.sale.dealID ?? ''}`
            + `&parm=${comments ?? ''}`
            + `&parm=${por ?? ''}`
            + `&parm=${pod ?? ''}`
            + `&parm=${source ?? ''}`
            + `&parm=${sink ?? ''}`
            + `&parm=${timezone ?? ''}`

        return post(url, profileInfo).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Schedule saved successfully.', { variant: 'success' });
                logAction('User saved schedule', 'Deal Rizz Scheduling', bookoutData);
            }
        });
    }

    function formatProfileInfo(data) {
        const profileData = [];
        gridRef.current.api.forEachNode(node => {
            if (node.data?.rowType === 'Schedule') {
                const dealID = node.data.dealID;
                const start = dayjs(bookoutData.date).startOf('day');
                profileHours.forEach((hour, idx) => {
                    const startDateTime = start.add(idx, 'hour').format('MM/DD/YYYY HH:mm');
                    const endDateTime = start.add(idx + 1, 'hour').format('MM/DD/YYYY HH:mm');
                    profileData.push({ dealID, startDateTime, endDateTime, capacityRequested: node.data[hour] });
                });
            }
        });
        return profileData;
    }

    const currencyFormatter = useCallback((formatOptions) => (params) => {
        const value = params.value;
        const isPriceRow = params.data?.id.toLowerCase().includes('price');
        if (value !== null && value !== undefined) {
            const options = isPriceRow ? { style: 'currency', currency: 'USD' } : formatOptions;
            // Format value as USD currency with commas
            return new Intl.NumberFormat('en-US', options).format(value);
        }
        return value;
    }, []);

    const defaultHourColDef = useCallback((i) => ({
        enableCellChangeFlash: true,
        colId: `${i}`,
        field: `${i}`,
        minWidth: 55,
        headerName: `${i}`,
        type: 'numericColumn',
        cellDataType: 'number',
        editable: (params) => params.data.type === 'bookout',
        valueFormatter: currencyFormatter(),
    }), [currencyFormatter]);

    const hourlyColDefs = useMemo(() => {
        return profileHours.map(hour => defaultHourColDef(hour));
    }, [defaultHourColDef, profileHours]);

    const colDefs = useMemo(() => [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 160,
            cellDataType: 'text',
            valueGetter: (params) => {
                if (params.data.type === 'deal') {
                    return `${params.data.dealName ?? params.data.id}`;
                } else {
                    return params.data.id;
                }
            },
        },
        ...hourlyColDefs,
    ], [hourlyColDefs]);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        flex: 1,
    }), []);

    const gridOptions = useMemo(() => {
        const typeAndThemeMatch = (type, mode) => {
            return (params) => {
                const rowType = params.data?.type;
                const match = rowType === type && theme.palette.mode === mode;
                return match;
            }
        }

        return {
            rowClassRules: {
                "ag-row-schedule": typeAndThemeMatch('schedule', 'light'),
                "ag-row-schedule-dark": typeAndThemeMatch('schedule', 'dark'),
                "ag-row-deal": typeAndThemeMatch('deal', 'light'),
                "ag-row-deal-dark": typeAndThemeMatch('deal', 'dark'),
                "ag-row-bookout": typeAndThemeMatch('bookout', 'light'),
                "ag-row-bookout-dark": typeAndThemeMatch('bookout', 'dark'),
            },
        }
    }, [theme.palette.mode]);

    const bookoutColorClass = theme.palette.mode === 'light' ? 'ag-row-bookout' : 'ag-row-bookout-dark';
    const dealColorClass = theme.palette.mode === 'light' ? 'ag-row-deal' : 'ag-row-deal-dark';
    const scheduleColorClass = theme.palette.mode === 'light' ? 'ag-row-schedule' : 'ag-row-schedule-dark';

    const calculateRemaining = useCallback((deal, schedules) => {
        const remaining = profileHours.reduce((acc, hour) => {
            const hourlyRemaining = schedules.reduce((acc, schedule) => {
                const scheduleValue = schedule[hour] ?? 0;
                return acc - scheduleValue;
            }, deal[hour] ?? 0);

            acc[hour] = hourlyRemaining;
            return acc;
        }, {});

        return remaining;
    }, [profileHours]);

    const rowData = useMemo(() => {
        if (!bookoutData) return [];

        const existingDealRow = { ...bookoutData.deal, type: 'deal', id: `${bookoutData.deal.id} (Deal)`, };
        const dealPriceRow = { ...bookoutData.price, type: 'deal', };
        const schedules = bookoutData.schedules.map(schedule => ({
            ...schedule,
            type: 'schedule',
            id: `${schedule.id} (Schedule)`,
        }));
        const remainingHourly = calculateRemaining(existingDealRow, schedules);
        const remainingRow = { type: 'remaining', id: 'Remaining', ...remainingHourly, };
        const newBookoutRow = { id: 'Bookout MW', type: 'bookout', ...remainingHourly, };

        const defaultBookoutPrices = profileHours.reduce((acc, hour) => {
            return { ...acc, [hour]: 0, };
        }, {});
        const newBookoutPriceRow = { ...defaultBookoutPrices, type: 'bookout', id: `Bookout Price`, };

        return [
            existingDealRow,
            dealPriceRow,
            ...schedules,
            remainingRow,
            newBookoutRow,
            newBookoutPriceRow,
        ];
    }, [bookoutData, calculateRemaining, profileHours]);

    const getRowId = useCallback(params => `${params.data.id}`, []);

    const flowDate = dayjs(bookoutData?.date).format('MM/DD/YYYY');

    const applyIndexPrices = async () => {
        const { timezone } = getValues();
        const flowDatePlusOneDay = dayjs(flowDate).add(1, 'day').format('MM/DD/YYYY');

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchIndexProfile`
            + `&parm=${headers.userGuid}`
            + `&parm=${index}`
            + `&parm=${flowDate}`
            + `&parm=${flowDatePlusOneDay}`
            + `&parm=${timezone}`
            //+ `&parm=${indexType}`
            + `&parm=${'RT'}`

        return get(url).then(response => {
            if (response.status === 200) {
                const profileData = response.data.reduce((acc, profile) => {
                    const HE = dayjs(profile.startTime).hour() + 1;
                    const price = profile.Price;
                    acc[HE] = price;
                    return acc;
                }, { id: 'Bookout Price', type: 'bookout', });

                gridRef.current.api.applyTransaction({ update: [profileData] });
            }
        });
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={false}
            ref={contentContainerRef}
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle sx={{ pb: 0, pt: 0.5, px: 2 }}>
                <Stack spacing={4} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6'>Create Bookout on {flowDate}</Typography>
                    <Autocomplete
                        options={indexOptions}
                        value={index}
                        onChange={(e, v) => setIndex(v)}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Index"
                                size='small'
                                sx={{ width: 300, }}
                            />
                        )}
                    />
                    <Button
                        variant='contained'
                        disabled={!index}
                        onClick={() => {
                            applyIndexPrices();
                        }}
                    >
                        Apply Index Prices
                    </Button>

                    <Box sx={{ flexGrow: 1, }} />

                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Box className={dealColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                        <Typography align='center'>- Deal</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Box className={scheduleColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                        <Typography align='center'>- Schedule</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Box className={bookoutColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                        <Typography align='center'>- Bookout</Typography>
                    </Stack>
                    <IconButton onClick={closeDialog} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent className='flex-column' sx={{ overflowX: 'hidden', px: 2, }}>
                <AgGridContainer style={{ paddingY: theme.spacing(1), }}>
                    <AgGridReact
                        {...defaultGridOptions}
                        ref={gridRef}
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        domLayout={'autoHeight'}
                        gridOptions={gridOptions}
                        getRowId={getRowId}
                        groupDisplayType={'groupRows'}
                        groupDefaultExpanded={1}
                    />
                </AgGridContainer>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', pt: 0 }}>
                <Button onClick={closeDialog} color='error'>Cancel</Button>
                <Button color='primary' variant='contained'>Submit Bookout</Button>
            </DialogActions>
        </Dialog>
    );
}
