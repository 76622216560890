import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { IconButton, TextField } from "@mui/material";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import FormAutocomplete from "../../../FormControls/FormAutocomplete";
import FormDatePicker from "../../../FormControls/FormDatePicker";
import { useLookupValues } from '../../DealEntry/LookupContext';
import { RibbonGroupContainer } from "../../Ribbon";
import { RepublishViewButton } from "../../RibbonButtons/RepublishViewButton";

export const ViewForm = ({ onSubmit, formId, expanded }) => {
    const { control, handleSubmit, getValues, setValue, formState: { errors, }, } = useFormContext();
    const { lookupValues } = useLookupValues();

    const handleDateUpdate = useCallback(debounce((key, value) => {
        //make sure start date is before stop date
        const start = getValues('startDate');
        const stop = getValues('stopDate');
        if ((key === 'startDate' && dayjs(stop).isBefore(value)) || (key === 'stopDate' && dayjs(start).isAfter(value))) {
            setValue('startDate', value);
            setValue('stopDate', value);
        } else {
            setValue(key, value);
        }
    }, 250), [setValue, getValues]);

    function handleIncrementDates(days) {
        const start = getValues('startDate');
        const stop = getValues('stopDate');
        setValue('startDate', start.add(days, 'day'));
        setValue('stopDate', stop.add(days, 'day'));
    }

    const counterpartyOptions = useMemo(() => {
        const parties = lookupValues.filter(lookup => lookup.lookupField === 'Counterparty').map(lookup => lookup.value);
        return ['All', ...new Set(parties)];
    }, [lookupValues]);

    const transactionValues = useMemo(() => {
        const transactions = lookupValues.filter(lookup => lookup.lookupField === 'Transaction').map(lookup => lookup.value);
        return ['All', ...new Set(transactions)];
    }, [lookupValues]);

    const bookOptions = useMemo(() => {
        const transactions = lookupValues.filter(lookup => lookup.lookupField === 'Book').map(lookup => lookup.value);
        return ['All', ...new Set(transactions)];
    }, [lookupValues]);

    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <RibbonGroupContainer sx={{ alignItems: 'start' }}>
                <RepublishViewButton
                    expanded={expanded}
                />

                <div style={{ display: 'flex', alignItems: 'start', height: '100%' }}>
                    <IconButton size='small' onMouseDown={() => {
                        handleIncrementDates(-1);
                    }}>
                        <ArrowLeftIcon />
                    </IconButton>
                    <FormDatePicker
                        name='startDate'
                        control={control}
                        label="Flow Date Start"
                        onChange={(newValue) => handleDateUpdate('startDate', newValue?.startOf('date'))}
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.startDate,
                                helperText: errors.startDate?.message,
                                sx: { minWidth: 150, maxWidth: 150, }
                            }
                        }}
                    />&nbsp;
                    <FormDatePicker
                        name='stopDate'
                        control={control}
                        label="Flow Date End"
                        onChange={(newValue) => handleDateUpdate('stopDate', newValue?.startOf('date'))}
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.stopDate,
                                helperText: errors.stopDate?.message,
                                sx: { minWidth: 150, maxWidth: 150, }
                            }
                        }}
                    />
                    <IconButton size='small' onMouseDown={() => {
                        handleIncrementDates(1);
                    }}>
                        <ArrowRightIcon />
                    </IconButton>
                </div>

                <FormAutocomplete
                    autoHighlight
                    fullWidth
                    options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                    getOptionLabel={(option) => option.split(' ')[0]}
                    control={control}
                    name='timezone'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Prevailing Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                            sx={{ minWidth: 150, }}
                        />
                    )}
                />

                <FormAutocomplete
                    options={counterpartyOptions}
                    control={control}
                    name="counterparty"
                    disableClearable
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Counterparty"
                            size='small'
                            error={!!errors.counterparty}
                            helperText={errors.counterparty?.message}
                            sx={{ minWidth: 180, maxWidth: 180, }}
                        />
                    )}
                />

                <FormAutocomplete
                    options={transactionValues}
                    control={control}
                    name="transaction"
                    disableClearable
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Transaction"
                            size='small'
                            error={!!errors.transaction}
                            helperText={errors.transaction?.message}
                            sx={{ minWidth: 180, maxWidth: 180, }}
                        />
                    )}
                />

                <FormAutocomplete
                    options={bookOptions}
                    control={control}
                    name="book"
                    disableClearable
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Book"
                            size='small'
                            error={!!errors.book}
                            helperText={errors.book?.message}
                            sx={{ minWidth: 180, maxWidth: 180, }}
                        />
                    )}
                />

            </RibbonGroupContainer>
        </form>
    );
}
