import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { IconButton, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import FormAutocomplete from "../../../FormControls/FormAutocomplete";
import FormDatePicker from "../../../FormControls/FormDatePicker";
import { RibbonGroupContainer } from "../../Ribbon";
import { RepublishViewButton } from "../../RibbonButtons/RepublishViewButton";

export const ViewForm = ({ onSubmit, formId, expanded }) => {
    const { handleSubmit, formState: { errors }, control, getValues, setValue, watch } = useFormContext();
    const timezone = watch('timezone');

    const handleDateUpdate = (key, value) => {
        //make sure start date is before stop date
        const start = getValues('startDate');
        const end = getValues('endDate');
        if ((key === 'startDate' && dayjs(end).isBefore(value)) || (key === 'endDate' && dayjs(start).isAfter(value))) {
            setValue('startDate', value);
            setValue('endDate', value);
        } else {
            setValue(key, value);
        }
    }

    function handleIncrementDates(days) {
        const startDate = getValues('startDate');
        const endDate = getValues('endDate');
        setValue('startDate', startDate.add(days, 'day'));
        setValue('endDate', endDate.add(days, 'day'));
    }

    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <RibbonGroupContainer>
                <RepublishViewButton expanded={expanded} />

                <div style={{ display: 'flex', alignItems: 'start' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(-1);
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <FormDatePicker
                            name='startDate'
                            control={control}
                            label="Flow Date Start"
                            onChange={(newValue) => handleDateUpdate('startDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.startDate,
                                    helperText: errors.startDate?.message,
                                    sx: { width: '170px', },
                                }
                            }}
                        />&nbsp;
                        <FormDatePicker
                            name='endDate'
                            control={control}
                            label="Flow Date End"
                            onChange={(newValue) => handleDateUpdate('endDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.endDate,
                                    helperText: errors.endDate?.message,
                                    sx: { width: '170px', },
                                }
                            }}
                        />
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(1);
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </LocalizationProvider>
                </div>

                <FormAutocomplete
                    autoHighlight
                    options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                    getOptionLabel={(option) => option.split(' ')[0]}
                    control={control}
                    name='timezone'
                    disableClearable
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Prevailing Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                            sx={{ minWidth: '170px', }}
                        />
                    )}
                />

            </RibbonGroupContainer>
        </form>
    );
}
