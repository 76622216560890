import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { DashboardLazy } from "../Dashboard/DashboardLazy.js";
import { ReviewView } from "./ReviewView.js";

export const ReviewDashboard = ({ visible, }) => {
    const dashboardKey = 'review-dashboard';
    const useShared = true;

    return (
        <DashboardLazy
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={ReviewView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}
