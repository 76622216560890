import AddIcon from '@mui/icons-material/Add';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Refresh from "@mui/icons-material/Refresh";
import { Alert, Box, Tooltip, } from '@mui/material';
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useRef, useState } from 'react';
import { AgGridContainer } from "../AgGrid/AgGridContainer";
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../AgGrid/defaultGridProps";
import { Ribbon, RibbonButton, RibbonGroupContainer } from "../DealRizz/Ribbon";
import { diffData } from "../DealRizz/Utils";
import useHubObject from "../HubContext/useHubObjectNoDebounce";
import { columnPanel, filterPanel } from "../ToolPanels/DefaultToolPanels";
import ConfirmationDialog from "../TSRActivity/ConfirmationDialog";
import { useApi } from "../useApi";
import { useUserInfo } from "../UserInfoContext";
import { AddLookupValueDialog } from "./AddLookupValueDialog";
import { CleanUpLookupFieldDialog } from "./CleanUpLookupFieldDialog";

export const LookupValuesManager = () => {
    const [selectedRow, setSelectedRow] = useState([]);
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({ open: false, });
    const [addLookupValueDialogOpen, setAddLookupValueDialogOpen] = useState(false);
    const [cleanUpDialogProps, setCleanUpDialogProps] = useState({ open: false, });
    const gridRef = useRef();
    const { get, enqueueSnackbar, logAction, apiUrlPrefix, headers } = useApi();
    const allowDelete = selectedRow.dealUsage === 0;
    const userInfo = useUserInfo();
    const [allowCleanUp, setAllowCleanUp] = useState(true);

    const getRowId = useCallback((params) => {
        const { data, } = params;
        return `${data.lookupValuesID}`;
    }, []);

    const fetchData = useCallback(async (api = gridRef.current.api) => {
        api?.showLoadingOverlay();
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchLookupValues&parm=${headers.userGuid}`

        return get(url).then(response => {
            if (response?.data && api) {
                const newData = response?.data ?? [];

                //clear old data
                const oldData = [];
                api.forEachLeafNode(node => {
                    oldData.push(node.data);
                });

                const { toAdd, toUpdate, toDelete, } = diffData(newData, oldData, (data) => getRowId({ data, }));
                api.applyTransaction({ add: toAdd, update: toUpdate, remove: toDelete });
            }
        });
    }, [get, apiUrlPrefix, headers, getRowId]);

    const handleHubUpdate = useCallback(() => {
        enqueueSnackbar('Lookup values have been updated. Refreshing data...', { variant: 'info' });
        fetchData();
        setTimeout(() => {
            setAllowCleanUp(true);
        }, 1000);
    }, [fetchData, enqueueSnackbar]);

    useHubObject({
        action: handleHubUpdate,
        allowedMessages: ['lookupValuesChanged'],
        predicate: (obj) => {
            return (obj.tenantID?.toString() === userInfo?.tenantId?.toString())
        },
    });

    const handleAddNew = useCallback(async (data) => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_insertLookupValue`
            + `&parm=${headers.userGuid}`
            + `&parm=${data.lookupField}`
            + `&parm=${data.value}`
            + `&parm=${data.notes}`;

        return get(url).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Lookup value added successfully. Refreshing data...', { variant: 'success' });
                logAction('User added lookup value.', 'Lookup Values', data);
                fetchData();
                //need the proc to return the new id to use the following code
                /*gridRef.current.api.applyTransaction({
                    add: [{
                        ...data,
                        toolTip: data.notes,
                        dealUsage: 0,
                        dealTemplateUsage: 0,
                    }], addIndex: 0
                });*/
            }
        });
    }, [logAction, enqueueSnackbar, apiUrlPrefix, headers, get, fetchData]);

    const handleDelete = useCallback(async (rowData) => {
        setConfirmationDialogProps({ open: false, });

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_deleteLookupValue`
            + `&parm=${headers.userGuid}`
            + `&parm=${rowData.lookupField}`
            + `&parm=${rowData.value}`;

        return get(url).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Lookup value deleted successfully.', { variant: 'success' });
                logAction('User deleted lookup value.', 'Lookup Values', rowData);
                gridRef.current.api.applyTransaction({ remove: [rowData] });
            }
        });
    }, [apiUrlPrefix, headers, enqueueSnackbar, logAction, get]);

    const handleConfirmDelete = useCallback((rowData = selectedRow) => {
        setConfirmationDialogProps({
            open: true,
            title: 'Confirm Delete',
            message: `Are you sure you want to delete the selected lookup value with field ${rowData.lookupField} and value ${rowData.value}?`,
            onConfirmation: () => handleDelete(rowData),
            onCancel: () => setConfirmationDialogProps({ open: false, }),
        });
    }, [handleDelete, selectedRow]);

    const handleCleanUp = useCallback(async (data) => {
        enqueueSnackbar('Success. The grid will refresh when clean up is finished.', { variant: 'success' });
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_cleanUpDealData`
            + `&parm=${headers.userGuid}`
            + `&parm=${data.lookupField}`
            + `&parm=${data.value}`
            + `&parm=${data.newValue}`;

        setAllowCleanUp(false);

        const config = {
            timeout: 5 * 60 * 1000, // 5 minutes
        };

        return get(url, config).then(response => {
            if (response.status === 200) {
                setAllowCleanUp(false);
                logAction('User cleaned up lookup value.', 'Lookup Values', data);
            }
        });
    }, [logAction, enqueueSnackbar, apiUrlPrefix, headers, get]);

    const columnDefs = useMemo(() => [
        {
            field: 'lookupValuesID',
            initialHide: true,
        },
        {
            field: 'lookupField',
            headerName: 'Lookup Field',
            checkboxSelection: true,
        },
        {
            field: 'value',
            headerName: 'Value',
        },
        {
            field: 'createdBy',
            headerName: 'Creator',
        },
        {
            field: 'toolTip',
            headerName: 'Tooltip',
        },
        {
            field: 'dealUsage',
            headerName: 'Deal Usage',
        },
        {
            field: 'dealTemplateUsage',
            headerName: 'Deal Template Usage',
        },
        {
            field: 'notes',
            headerName: 'Notes',
        },
    ], []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        flex: 1,
    }), []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
        ]
    }), []);

    const onGridReady = useCallback((params) => {
        fetchData(params.api);
    }, [fetchData]);

    const handleSelectionChanged = useCallback((params) => {
        const selectedNodes = params.api.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        setSelectedRow(selectedData[0] ?? {});
    }, []);

    const getContextMenuItems = useCallback((params) => [
        'cut',
        'copy',
        'copyWithHeaders',
        'copyWithGroupHeaders',
        'paste',
        {
            name: 'Clean Up Lookup Field',
            action: () => setCleanUpDialogProps({
                open: true,
                lookupData: params.node.data,
            }),
        },
        {
            name: 'Add New Lookup Value',
            action: () => setAddLookupValueDialogOpen(true),
        },
        {
            name: 'Delete Lookup Value',
            disabled: params.node.data.dealUsage !== 0,
            action: () => handleConfirmDelete(params.node.data),
        },
        'separator',
        'export',
    ], [handleConfirmDelete]);

    const cleanupTooltip = allowCleanUp
        ? 'Clean up deal data across the board by updating a field to a new value.'
        : 'A clean up is in progress. Please wait for it to finish before starting another.';

    return (
        <Box className='flex-column'>
            <ConfirmationDialog {...confirmationDialogProps} />
            <AddLookupValueDialog
                open={addLookupValueDialogOpen}
                onClose={() => setAddLookupValueDialogOpen(false)}
                onAdd={handleAddNew}
            />
            <CleanUpLookupFieldDialog
                {...cleanUpDialogProps}
                onCleanUp={(data) => handleCleanUp(data)}
                onClose={() => setCleanUpDialogProps({ open: false, })}
            />

            <Ribbon>
                {[
                    {
                        title: 'Actions',
                        content: <RibbonGroupContainer>
                            <Tooltip title={cleanupTooltip}>
                                <span>
                                    <RibbonButton
                                        icon={<CleaningServicesIcon />}
                                        label={'Clean Up'}
                                        disabled={!selectedRow?.lookupField || !allowCleanUp}
                                        expanded
                                        onClick={() => setCleanUpDialogProps({
                                            open: true,
                                            lookupData: selectedRow,
                                        })}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title="Create a new lookup value.">
                                <span>
                                    <RibbonButton
                                        icon={<AddIcon />}
                                        label={'Add New'}
                                        disabled={selectedRow?.lookupField || !allowCleanUp}
                                        expanded
                                        onClick={() => setAddLookupValueDialogOpen(true)}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title="Delete the selected lookup value.">
                                <span>
                                    <RibbonButton
                                        icon={<DeleteForeverIcon />}
                                        label={'Delete'}
                                        // disabled={!allowDelete}
                                        disabled={!selectedRow?.lookupField || !allowDelete || !allowCleanUp}
                                        expanded
                                        onClick={() => handleConfirmDelete()}
                                    />
                                </span>
                            </Tooltip>
                        </RibbonGroupContainer>
                    },
                    {
                        title: 'Grid',
                        content: <RibbonGroupContainer>
                            <Tooltip title="Refresh the data in the grid.">
                                <span>
                                    <RibbonButton
                                        icon={<Refresh />}
                                        label={'Refresh'}
                                        disabled={!allowDelete || !allowCleanUp}
                                        expanded
                                        onMouseDown={() => fetchData()}
                                    />
                                </span>
                            </Tooltip>
                        </RibbonGroupContainer>
                    },
                    { // Add a new group to show a status indicator if allowCleanUp is false
                        title: 'Status',
                        content: (
                            <RibbonGroupContainer>
                                {!allowCleanUp && (
                                    <div style={{ padding: '0 10px' }}>
                                        <Alert severity="info">The Crystal Ball API is processing cleanup. Please wait...</Alert>
                                    </div>
                                )}

                                {allowCleanUp && (
                                    <div style={{ padding: '0 10px' }}>
                                        <Alert severity="success">Ready for cleanup</Alert>
                                    </div>
                                )}
                            </RibbonGroupContainer>
                        ),
                    },
                ]}
            </Ribbon>

            <AgGridContainer style={{ flexGrow: 1, }}>
                <AgGridReact
                    {...defaultGridOptions}
                    ref={gridRef}
                    columnDefs={columnDefs}
                    getRowId={getRowId}
                    defaultColDef={defaultColDef}
                    statusBar={defaultStatusBar}
                    onGridReady={onGridReady}
                    sideBar={sideBar}
                    rowSelection='single'
                    rowMultiSelectWithClick
                    onSelectionChanged={handleSelectionChanged}
                    getContextMenuItems={getContextMenuItems}
                />
            </AgGridContainer>
        </Box>
    )
}
