import CriteriaPanel from './CriteriaPanel';
import { useState, useRef, useContext, useEffect } from 'react';
import useHeader from "../useHeader";
import { useSnackbar } from "notistack";
import { useLocalGuid } from "../../data/UserGuidContext/useLocalGuid";
import { apiUrlPrefix } from "../../authConfig";
import dayjs from 'dayjs';
import axios from "axios";
import { Box, } from "@mui/material";
import { useActionAudit } from '../useActionAudit';
import { useMsal } from '@azure/msal-react';
import { UserContext } from "../../data/fetchUserGroups";
import ViewContainer from "./ViewContainer";
import { userGroups } from '../../authConfig';
import { useIPContext } from '../IPContext/useIPContext';
import Dashboard from './Dashboard/Dashboard';
import { DivGuard } from '../Guards/DivGuardFlex';

const FastPathPro = ({ view, }) => {
    const [gridData, setGridData] = useState([]);
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();
    const { accounts } = useMsal();
    const gridRef = useRef();
    const guid = useLocalGuid();
    const groups = useContext(UserContext);
    const proUser = groups.includes(userGroups.admins) || groups.includes(userGroups.fastpathpro);
    const ip = useIPContext();
    const abortControllerRef = useRef(new AbortController());
    const [disableFetch, setDisableFetch] = useState(false);
    const [dashboardViews, setDashboardViews] = useState();

    const [searchCriteria, setSearchCriteria] = useState({});

    //Takes an array, returns a comma separated string
    function collapseArray(arr) {
        return arr?.length ? arr.reduce((current, next, i) => `${current}${(i > 0) ? ',' : ''}${next}`) : '';
    }

    function handleFetch(criteria) {
        setSearchCriteria(criteria);
        gridRef.current?.api && gridRef.current.api.showLoadingOverlay();
        setDisableFetch(true);

        const timestamp = dayjs();
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fastPathProFetch_v2`
            + `&parm=${guid ?? ''}`
            + `&parm=${criteria.maxLegs ?? ''}`
            + `&parm=${criteria.por ?? ''}`
            + `&parm=${criteria.pod ?? ''}`
            + `&parm=${collapseArray(criteria.excludeTp)}`
            + `&parm=${criteria.minCapacity ?? ''}`
            + `&parm=${criteria.timezone ?? ''}`
            + `&parm=${criteria.startDate}`
            + `&parm=${criteria.stopDate}`
            + `&parm=${collapseArray(criteria.tsClass)}`
            + `&parm=${collapseArray(criteria.tsIncrement)}`
            + `&parm=${collapseArray(criteria.tsType)}`
            + `&parm=${collapseArray(criteria.tsPeriod)}`
            + `&parm=${collapseArray(criteria.tsWindow)}`
            + `&parm=${collapseArray(criteria.tsSubclass)}`
            + `&parm=${collapseArray(criteria.excludePoints)}`
            + `&parm=${criteria.hardLimit ? 1 : 0}`
            + `&parm=${ip}`

        const options = {
            headers: headers,
            url: url,
            signal: abortControllerRef.current.signal,
        }

        const logMessage = `${accounts[0]?.username ?? 'Unauthenticated user'} used Fast Path Pro to find ${criteria.minCapacity}MW of `
            + `${criteria.tsClass ?? ''} ${criteria.tsIncrement ?? ''} ${criteria.tsType ?? ''} ${criteria.tsPeriod ?? ''} ${criteria.tsWindow ?? ''} ${criteria.tsSubclass ?? ''}`
            + ` trans ${criteria.por}-${criteria.pod} ${criteria.excludePoints ? ` excluding points ${criteria.excludePoints}` : ''}`
            + `${criteria.excludeTp ? ` excluding providers ${criteria.excludeTp}` : ''}`

        logAction(logMessage, 'Fast Path Pro', { IP: ip, ...criteria })

        axios(options).then(response => {
            setGridData(response?.data ?? []);
            //gridRef.current?.api && gridRef.current.api.hideOverlay();
            console.log(`Success fetching paths. ${response.data.length} rows returned. Fetch took ${dayjs().diff(timestamp)}ms.`)
            logAction(`Success fetching paths. ${response.data.length} rows returned. Fetch took ${dayjs().diff(timestamp)}ms.`, 'Fast Path Pro', { IP: ip, ...criteria })
            setDisableFetch(false);
        }).catch(error => {
            if (error.message !== 'canceled') { //don't show an error if the user canceled the fetch 
                const message = (error.response?.data?.includes('Crystal')) ? error.response?.data?.split('.')[0] : error.message;
                enqueueSnackbar(`Error fetching paths. ${message}`, { variant: 'error' });
                logAction(`Fast path fetch failed: ${message}. Status: ${error.response?.status}`, 'Fast Path Pro', { IP: ip, ...criteria })
            } else {
                logAction('Fetch canceled by user.', 'Fast Path Pro', { IP: ip, ...criteria })
            }
            gridRef.current?.api && gridRef.current.api.hideOverlay();
            setGridData([]);
            setDisableFetch(false);
            gridRef.current?.api && gridRef.current.api.showNoRowsOverlay();
        })
    }

    function handleCancelFetch() {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
    }

    useEffect(() => {
        fetchDashboardViews();
    }, []);

    async function fetchDashboardViews() {
        const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutFetch_v2&parm=${guid}&parm=fastPathDashboard`;
        const options = {
            headers: headers,
            url: loadUri,
        }
        axios(options).then(response => {
            const views = response?.data ?? [];
            setDashboardViews(views.map(viewData => {
                const view = JSON.parse(viewData.json ?? '{}');
                return {
                    ...view,
                    label: viewData.template,
                    id: viewData.userLayoutID,
                }
            }));
        }).catch(err => {
            enqueueSnackbar(`Error getting view data in for Fast Path Dashboard.  ${err}`, { variant: 'error', });
            setDashboardViews([]);
        });
    }

    async function handleSaveDashboardView(criteria, refresh = true) {
        const newView = {
            grid: 'fastPathDashboard',
            json: {
                ...criteria,
            },
            template: criteria.label,
            id: criteria.id ?? '',
        }

        const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UserLayoutInsertUpdate_v2&parm=${guid}&parm=${newView.grid}&parm=${newView.template}&parm=${newView.id}`;

        const options = {
            method: 'POST',
            headers: headers,
            data: newView.json,
            url: saveUri,
        }

        return axios(options).then(response => {
            refresh && fetchDashboardViews()
        }).catch(err => { enqueueSnackbar(`Error saving Fast Path Dashboard grid data.  ${err.response?.data ?? err.message}`, { variant: 'error', }) });
    }

    return (
        (view === 'dashboard') ? (
            <DivGuard
                groups={[userGroups.fastpathdashboard, userGroups.fastpathpro]}
                message='Results will be limited in the demo version. Please contact support to upgrade.'
            >
                <Dashboard
                    dashboardViews={dashboardViews}
                    handleSave={handleSaveDashboardView}
                />
            </DivGuard>
        ) : (
            <DivGuard
                groups={[userGroups.fastpathpro, userGroups.fastpath, userGroups.fastpathdashboard]}
                allowDisable={false}
                message='Results will be limited in the demo version. Please contact support to upgrade.'
            >
                <Box sx={{ display: 'flex', width: '100%', }}>
                    <CriteriaPanel
                        handleFetch={handleFetch}
                        isProUser={proUser}
                        disableFetch={disableFetch}
                    />
                    <ViewContainer
                        data={gridData}
                        ref={gridRef}
                        criteria={searchCriteria}
                        handleCancelFetch={handleCancelFetch}
                        handleSaveToDashboard={handleSaveDashboardView}
                    />
                </Box>
            </DivGuard>
        )
    )
}

export default FastPathPro;
