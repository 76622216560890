import { useEffect, useState } from 'react';
import useHeader from '../../useHeader';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { apiUrlPrefix } from '../../../authConfig';
import { CriteriaContext } from './criteriaContext'; 
import { useLocalGuid } from '../../../data/UserGuidContext/useLocalGuid';

export default ({ children }) => {
    const headers = useHeader();
    const guid = useLocalGuid();
    const { enqueueSnackbar } = useSnackbar();
    const [providers, setProviders] = useState([]);
    const [combinations, setCombinations] = useState([]);
    const [tsCombinations, setTsCombinations] = useState([]);
    const [customerCodes, setCustomerCodes] = useState([]);
    const [relatedRefs, setRelatedRefs] = useState([]);
    
    //hardcoded criteria fields here
    //const serviceIncrements = [ 'Hourly', 'Daily', 'Weekly', 'Monthly', 'Yearly', ];
    const requestTypes = [ 'Original', 'Recall', 'Redirect', 'Resale', 'Relinquish', 'Consolidation', 'Linked', 'Renewal' ];
    const statuses = [ 'ACCEPTED', 'ANNULLED', 'CONFIRMED', 'COUNTEROFFER', 'DECLINED', 'INVALID', 'QUEUED', 'RECIEVED', 'REFUSED', 'RETRACTED', 'STUDY', 'WITHDRAWN'];

    useEffect(() => {
        fetchPorPodChoices();
        fetchTSCombinations();
        fetchCustomerCodes();
        fetchRelatedRefs();
      }, []);
    
    async function fetchCustomerCodes() {
      const options = {
        headers: headers,
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_CustomerCodeChoiceFetch`,
      }
    
      axios(options).then(response => {
        setCustomerCodes(response.data.map(entry => entry.customerCode));
      }).catch(error => {
        enqueueSnackbar(`Error fetching customer codes. Message: ${error}`)
      });
    }

    async function fetchRelatedRefs() {
      const options = {
        headers: headers,
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_SpyMonkeyRedirectsFetch&parm=${guid}`,
      }
    
      axios(options).then(response => {
        setRelatedRefs(response.data);
      }).catch(error => {
        enqueueSnackbar(`Error fetching related refs. Message: ${error}`)
      });
    }


    async function fetchTSCombinations() {
      const options = {
        headers: headers,
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchTSCombinations`,
      }
    
      axios(options).then(response => { 
        setTsCombinations(response.data);
      }).catch(error => {
        enqueueSnackbar(`Error fetching TS combinations. Message: ${error}`)
      });
    }
  
    async function fetchPorPodChoices() {
      const options = {
        headers: headers,
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchAllPorPodCombinations_v2`,
      }
    
      axios(options).then(response => { 
        setCombinations(response.data);
        setProviders([...new Set(response.data.map(combo => combo.Provider))]);
      }).catch(error => {
        enqueueSnackbar(`Error fetching provider choices. Message: ${error}`)
      });
    }

    const data = {
      providers: providers,
      combinations: combinations,
      tsCombinations: tsCombinations,
      customerCodes: customerCodes,
      relatedRefs: relatedRefs,
      //serviceIncrements: serviceIncrements,
      requestTypes: requestTypes,
      statuses: statuses,
    }
 
    return (
      <CriteriaContext.Provider value={data}>
        {children}
      </CriteriaContext.Provider>
    )
}