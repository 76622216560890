import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useCallback, useState } from 'react';
import { useFormContext } from "react-hook-form";
import { RibbonGroupContainer } from '../../Ribbon';
import { RefreshButton } from "../../RibbonButtons";

export const GridActionsGroup = ({ formId, expanded, gridRef, handleFetchData, toggleHourlyColumns }) => {
    const { getValues } = useFormContext();
    const showMWColumns = getValues('showMWColumns');
    const showPriceColumns = getValues('showPriceColumns');
    const [MWColumns, setMWColumns] = useState(!!showMWColumns);
    const [priceColumns, setPriceColumns] = useState(!!showPriceColumns);

    const handleToggle = useCallback((showMW, showPrice) => {
        const flowDate = getValues('date');
        toggleHourlyColumns(showMW, showPrice, flowDate);
    }, [getValues, toggleHourlyColumns]);

    const toggleShowMWColumns = (_, newVal) => {
        handleToggle(newVal, priceColumns);
        setMWColumns(!MWColumns);
    }

    const toggleShowPriceColumns = (_, newVal) => {
        handleToggle(MWColumns, newVal);
        setPriceColumns(!priceColumns);
    }

    return (
        <RibbonGroupContainer>
            <RefreshButton
                formId={formId}
                expanded={expanded}
                onRefresh={handleFetchData}
            />

            <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormControlLabel
                    label="Show MW Columns"
                    slotProps={{
                        typography: { variant: 'caption', }
                    }}
                    control={
                        <Switch
                            checked={!!MWColumns}
                            onChange={toggleShowMWColumns}
                        />
                    }
                />
            </FormGroup>

            <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormControlLabel
                    label="Show Price Columns"
                    slotProps={{
                        typography: { variant: 'caption', }
                    }}
                    control={
                        <Switch
                            checked={!!priceColumns}
                            onChange={toggleShowPriceColumns}
                        />
                    }
                />
            </FormGroup>
        </RibbonGroupContainer>
    );
}
