import { DeleteForever, } from "@mui/icons-material";
import { FormProvider, useForm, } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Divider, FormControlLabel, Stack, Typography, ListItem, List, ListItemText, Tooltip, IconButton, } from "@mui/material";
import { SubmissionFooter } from "./SubmissionFooter";
import { Children, cloneElement, forwardRef, useState, useLayoutEffect, useMemo, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { ProfileEditorDialog } from './ProfileEditor/ProfileEditorDialog';
import ConfirmationDialog from "../../TSRActivity/ConfirmationDialog";
import { useTransmissionTemplates } from "../../useTransmissionTemplates";
import { useTheme } from "@emotion/react";
import dayjs from 'dayjs';
import { useLookupValues } from './LookupContext';
import { formFieldDefs } from './FormFieldDefs';
import { renderStandardDayjsTimezones, renderStandardTimezones } from '../../../utils/renderTimezones';
import { useApi } from '../../useApi';
import { DealEntryForm } from './DealEntryForm';
import { debounce } from 'lodash';
import { useUserInfo } from "../../UserInfoContext";
import { jsonOrCommaSeparatedFormatter } from "../Utils";
import { useUserGroups } from "../../../data/useUserGroups";
import { userGroups } from "../../../authConfig";

export const DealEntry = forwardRef(({ handleCancel, handleSaveAndClose, dealToEdit, }, ref) => {
    const formId = 'deal-entry-form';
    const { post, apiUrlPrefix, headers, logAction, enqueueSnackbar, } = useApi();

    const handleSave = useCallback(debounce((data) => {
        handleCancel(); //close the dialog immediately to prevent multiple submissions
        if (dealToEdit) {
            return handleEdit(data);
        } else {
            return handleInsertNew(data);
        }
    }, 3000, { leading: true, trailing: false, }), [dealToEdit]);

    async function handleEdit(data) {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_dealEdit_v7`
            + `&parm=${headers.userGuid}`
            + `&parm=${dealToEdit.dealID}`
            + `&parm=${JSON.stringify(data.Strategy)}`
            + `&parm=${dayjs(data.Trade_Date).format('MM/DD/YYYY')}`
            + `&parm=${JSON.stringify(data.Book)}`
            + `&parm=${data.Contract}`
            + `&parm=${data.dealToken}`
            + `&parm=${data.Transaction_Type}`
            + `&parm=${data.Transaction}`
            + `&parm=${data.Trader}`
            + `&parm=${data.Counterparty}`
            + `&parm=${renderStandardDayjsTimezones[data.timezone]}`
            + `&parm=${data.Term}`
            + `&parm=${dayjs(data.dealStartTime).format('MM/DD/YYYY HH:mm:ss')}`
            + `&parm=${dayjs(data.dealStopTime).format('MM/DD/YYYY HH:mm:ss')}`
            + `&parm=${data.Financial_Type}`
            + `&parm=${data.Market}`
            + `&parm=${data.Zone}`
            + `&parm=${data.por ?? ''}`
            + `&parm=${data.pod ?? ''}`
            + `&parm=${data.Index}`
            + `&parm=${data.confirmed ? 1 : 0}`
            + `&parm=${data.comments}`
            + `&parm=${data.adder ? parseFloat(data.adder).toFixed(2) : ''}`
            + `&parm=${data.indexType ?? 'None'}`
            + `&parm=${data.forecast ?? 'None'}`
            + `&parm=${data.dynamic ? 1 : 0}`

        // enqueueSnackbar(JSON.stringify(url));

        return post(url, data.profile).then(response => {
            if (response?.status === 200) {
                enqueueSnackbar('Deal edited successfully.', { variant: 'success' });
                handleSaveAndClose(); //close dialog and refresh
                logAction('User saved an edited deal.', 'Deal Entry', data);
                localStorage.removeItem(formId);
            }
        });
    }

    async function handleInsertNew(data) {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.dealInsert_v5`
            + `&parm=${headers.userGuid}`
            + `&parm=${JSON.stringify(data.Strategy)}`
            + `&parm=${dayjs(data.Trade_Date).format('MM/DD/YYYY')}`
            + `&parm=${JSON.stringify(data.Book)}`
            + `&parm=${data.Contract}`
            + `&parm=${data.dealToken}`
            + `&parm=${data.Transaction_Type}`
            + `&parm=${data.Transaction}`
            + `&parm=${data.Trader}`
            + `&parm=${data.Counterparty}`
            + `&parm=${renderStandardDayjsTimezones[data.timezone]}`
            + `&parm=${data.Term}`
            + `&parm=${dayjs(data.dealStartTime).format('MM/DD/YYYY HH:mm:ss')}`
            + `&parm=${dayjs(data.dealStopTime).format('MM/DD/YYYY HH:mm:ss')}`
            + `&parm=${data.Financial_Type}`
            + `&parm=${data.Market}`
            + `&parm=${data.Zone}`
            + `&parm=${data.por ?? ''}`
            + `&parm=${data.pod ?? ''}`
            + `&parm=${data.Index}`
            + `&parm=${data.confirmed ? 1 : 0}`
            + `&parm=${data.comments}`
            + `&parm=${data.adder ? parseFloat(data.adder).toFixed(2) : ''}`
            + `&parm=${data.indexType ?? 'None'}`
            + `&parm=${data.forecast ?? 'None'}`
            + `&parm=${data.dynamic ? 1 : 0}`

        // enqueueSnackbar(JSON.stringify(url));

        return post(url, data.profile).then(response => {
            enqueueSnackbar('Deal saved successfully.', { variant: 'success' });
            handleSaveAndClose(); //close dialog and refresh
            logAction('User saved inserted a deal.', 'Deal Entry', data);
            localStorage.removeItem(formId);
        });
    }

    return (
        <Box sx={{ px: 1, pb: 1, width: '100%' }} ref={ref}>
            <DealEntryFormProvider handleSave={handleSave} formId={formId} dealToEdit={dealToEdit}>
                <DealEntryForm />
                <Divider sx={{ p: 1, }} />
                <SubmissionFooter handleCancel={handleCancel} />
            </DealEntryFormProvider>
        </Box>
    );
});

const DealEntryFormProvider = ({ children, handleSave, formId, dealToEdit, }) => {
    const [openProfileEditor, setOpenProfileEditor] = useState(false);
    const theme = useTheme();
    const { getOptions, lookupValues } = useLookupValues();
    const [options, setOptions] = useState({});
    const isEdit = !!dealToEdit;
    const userInfo = useUserInfo();
    const { userIsInGroup } = useUserGroups();
    const tenantAdmin = userIsInGroup(userGroups.tenantAdmin);
    const { logAction } = useApi();

    const formDefaults = useMemo(() => {
        return formFieldDefs.reduce((acc, field) => {
            acc[field.key] = field.defaultValue;
            return acc;
        }, {});
    }, []);

    //try to parse the profileJson from the dealToEdit object to default the profile; if that fails, default to an empty array
    const profileJson = dealToEdit?.profileJson;
    if (profileJson) {
        try {
            dealToEdit.profile = JSON.parse(profileJson);
        } catch (e) {
            dealToEdit.profile = [];
        }
    }

    let tz;
    if (dealToEdit?.Time_Zone) {
        tz = dealToEdit.Time_Zone;
        if (tz.includes('Standard')) {
            tz = renderStandardDayjsTimezones[tz];
        } else if (renderStandardTimezones[tz]?.includes('Standard')) {
            tz = renderStandardDayjsTimezones[renderStandardTimezones[tz]];
        } else if (!renderStandardDayjsTimezones[tz]) {
            tz = 'America/Los_Angeles';
        }
    }

    const defaults = useMemo(() => {
        let defaultValues;
        if (dealToEdit) {
            defaultValues = {
                ...formDefaults,
                ...dealToEdit,
                timezone: tz,
                dealToken: dealToEdit.Deal_Number,
                Index: dealToEdit.index_name,
                Transaction: dealToEdit.Type_F_P,
                Trade_Date: dayjs(dealToEdit.Trade_Date),
                dealStartTime: dayjs(dealToEdit.Start_Date),
                dealStopTime: dayjs(dealToEdit.End_Date),
                Book: formatDefaultForPercentageDropdown(dealToEdit.Book),
                Strategy: formatDefaultForPercentageDropdown(dealToEdit.Strategy),
                comments: dealToEdit.Internal,
            }
        } else {
            const savedValues = localStorage.getItem(formId);
            const savedData = savedValues ? JSON.parse(savedValues) : {};
            defaultValues = {
                ...formDefaults,
                ...savedData,
                Book: formatDefaultForPercentageDropdown(savedData.Book),
                Strategy: formatDefaultForPercentageDropdown(savedData.Strategy),
                Trade_Date: dayjs(),
                profile: [],
                adder: '',
                forecast: 'None',
                indexType: 'None',
                dynamic: false,
                dealStartTime: dayjs(formDefaults.dealStartTime),
                dealStopTime: dayjs(formDefaults.dealStopTime),
                Trader: userInfo?.userName,
            }
        }
        return defaultValues;
    }, []);

    function handleProfileUpdate(data) {
        setValue('profile', data.profile, { shouldValidate: true });
        setValue('timezone', data.timezone);
        setValue('indexType', data.indexType);
        setValue('dynamic', data.dynamic);
        setValue('adder', data.adder);
        setValue('forecast', data.forecast);
        setOpenProfileEditor(false);
        //if there is no Term, set it to the profile template
        if (!getValues('Term')) {
            setValue('Term', data.Term);
        }
    }

    const { enqueueSnackbar } = useSnackbar();

    const schema = yup.object().shape({
        Index: yup.string().required('Index is required').when('$_', {
            is: _ => !tenantAdmin,
            then: () => yup.string().oneOf(options['Index'] ?? [], 'Type must be a valid option'),
        }),
        Transaction_Type: yup.string().required('Transaction Type is required').when('$_', {
            is: _ => !tenantAdmin,
            then: () => yup.string().oneOf(options['Transaction_Type'] ?? [], 'Type must be a valid option'),
        }),
        Transaction: yup.string().required('Transaction is required').when('$_', {
            is: _ => !tenantAdmin,
            then: () => yup.string().oneOf(options['Transaction'] ?? [], 'Type must be a valid option'),
        }),
        Counterparty: yup.string().required('Counterparty is required').when('$_', {
            is: _ => !tenantAdmin,
            then: () => yup.string().oneOf(options['Counterparty'] ?? [], 'Type must be a valid option'),
        }),
        profile: yup.array().min(1, 'Profile is required'),
        Book: yup.array().min(1, 'Book is required').test(
            'percentagesAreIntegers',
            'Percentages must be integers.',
            (value) => { return value.every(item => /^[0-9]*$/.test(item.percentage)); }
        ).test(
            'percentagesAddTo100',
            'Book percentages must total 100%.',
            (value) => {
                const total = value.reduce((acc, item) => acc + parseInt(item.percentage), 0);
                return total === 100;
            }),
        Contract: yup.string().required('Contract is required').when('$_', {
            is: _ => !tenantAdmin,
            then: () => yup.string().oneOf(options['Contract'] ?? [], 'Type must be a valid option'),
        }),
        Financial_Type: yup.string().required('Firm/Non-Firm is required').when('$_', {
            is: _ => !tenantAdmin,
            then: () => yup.string().oneOf(options['Financial_Type'] ?? [], 'Type must be a valid option'),
        }),
        Market: yup.string().required('Market is required').when('$_', {
            is: _ => !tenantAdmin,
            then: () => yup.string().oneOf(options['Market'] ?? [], 'Type must be a valid option'),
        }),
        Zone: yup.string().required('Zone is required').when('$_', {
            is: _ => !tenantAdmin,
            then: () => yup.string().oneOf(options['Zone'] ?? [], 'Type must be a valid option'),
        }),
        // Use test for conditional POR validation
        por: yup.string().nullable().test(
            'is-required-por',
            'POR is required when Transaction Type is Purchase',
            function (value) {
                const { Transaction_Type } = this.parent;
                if (Transaction_Type === 'Purchase') {
                    return !!value; // Ensures value is required if Transaction_Type is 'Purchase'
                }
                return true; // Allows blank/null if not 'Purchase'
            }
        ).when('$_', {
            is: _ => !tenantAdmin,
            then: () => yup.string().oneOf(options['por'] ?? [], 'Type must be a valid option'),
        }),

        // Use test for conditional POD validation
        pod: yup.string().nullable().test(
            'is-required-pod',
            'POD is required when Transaction Type is Sale',
            function (value) {
                const { Transaction_Type } = this.parent;
                if (Transaction_Type === 'Sale') {
                    return !!value; // Ensures value is required if Transaction_Type is 'Sale'
                }
                return true; // Allows blank/null if not 'Sale'
            }
        ).when('$_', {
            is: _ => !tenantAdmin,
            then: () => yup.string().oneOf(options['pod'] ?? [], 'Type must be a valid option'),
        }),
        Trader: yup.string().required('Trader is required'),
        dealToken: yup.string().required('Deal Name is required'),
        Trade_Date: yup.date().required('Trade Date is required'),
        comments: yup.string(),
        Strategy: yup.array().test(
            'percentagesAreIntegers',
            'Percentages must be integers.',
            (value) => { return value.every(item => /^[0-9]*$/.test(item.percentage)); }
        ).test(
            'percentagesAddTo100',
            'Strategy percentages must total 100%.',
            (value) => {
                if (!value?.length) return true;
                const total = value.reduce((acc, item) => acc + parseInt(item.percentage), 0);
                return total === 100;
            }
        ),
        Term: yup.string().required('Term is required'),
        dealStartTime: yup.date().required('Start Date is required'),
        dealStopTime: yup.date().required('Stop Date is required'),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaults,
    });

    const { handleSubmit, watch, setValue, getValues, reset, getFieldState, } = methods;

    useEffect(() => {
        const subscription = watch((data) => updateOptions(data));
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        updateOptions(getValues());
    }, [lookupValues]);

    const dealTokenComponentFields = ['Book', 'Counterparty', 'Strategy'];
    const bookVal = watch('Book');
    const counterPartyVal = watch('Counterparty');
    const strategy = watch('Strategy');

    useEffect(() => {
        updateDealTokenValue();
    }, [bookVal, counterPartyVal, strategy]);

    function updateDealTokenValue() {
        const dealTokenFieldState = getFieldState('dealToken');
        const dealTokenValue = getValues('dealToken');
        if (dealTokenFieldState.isTouched && dealTokenFieldState.isDirty) { //if the field has been modified by the user, don't update it
            return;
        } else if (!dealTokenValue || dealTokenComponentFields.some(field => getFieldState(field).isDirty)) { //if any of the fields that make up the token have been modified, update the token; this way the saved value is persisted when loading for the first time.
            const tokenComponentValues = getValues(dealTokenComponentFields);
            //first one is book, reduce it to just the labels
            tokenComponentValues[0] = tokenComponentValues[0].map(item => item.label);
            //do the same for strategy, the last one
            tokenComponentValues[2] = tokenComponentValues[2].map(item => item.label);
            //trim the string ' Book' from the end of the book labels; do this case insensitively
            tokenComponentValues[0] = tokenComponentValues[0].map(item => item.replace(/ book$/i, ''));
            const newTokenValue = tokenComponentValues.join('-');
            setValue('dealToken', newTokenValue);
        }
    }

    const {
        TemplateAutocomplete,
        AddNewTemplateButton,
        AddNewTemplateDialog,
        confirmationDialogProps,
        handleDeleteTemplate,
        handleTemplateChange
    } = useTransmissionTemplates({
        getValues,
        reset,
        templateTypeId: 7,
        toIgnore: ['Trade_Date', 'comments', 'profile', 'Trader', 'dealStartTime', 'dealStopTime'],
    });

    useLayoutEffect(() => {
        return () => {
            const allValues = getValues();
            localStorage.setItem(formId, JSON.stringify(allValues));
        }
    }, []);

    const onSubmit = (data) => {
        handleSave(data);
    };

    function updateOptions(values) {
        setOptions(getOptions(values));
    }

    const childrenWithProps = Children.map(children, child =>
        cloneElement(child, { setOpenProfileEditor, formId, formDefaults, options, isEdit, })
    );

    function onErrors(errors) {
        const firstErrorKey = Object.keys(errors)[0];
        const firstError = errors[firstErrorKey];
        enqueueSnackbar(firstError.message, { variant: 'error' });
    }

    const allowDeleteTemplate = (template) => {
        return tenantAdmin || template.createdByUserGuid === userInfo.userGuid;
    }

    return (
        <FormProvider {...methods}>
            <Stack direction='row' spacing={3} sx={{ top: theme.spacing(3), left: theme.spacing(4), position: 'absolute', alignItems: 'center' }}>
                {dealToEdit && <Typography variant='title'>Deal ID: {dealToEdit.dealID}</Typography>}
                <AddNewTemplateDialog />
                <ConfirmationDialog {...confirmationDialogProps} />
                {!isEdit && <FormControlLabel
                    labelPlacement="start"
                    control={<TemplateAutocomplete
                        sx={{ minWidth: '360px', }}
                        onChange={(_, newVal) => {
                            if (!newVal) return;

                            logAction('User selected a template.', 'Deal Entry', newVal);
                            handleTemplateChange(newVal);
                        }}
                        renderOption={(props, option) => {
                            const parsedTemplate = JSON.parse(option.json);
                            return (
                                <Tooltip placement='left' title={
                                    <Box sx={{ pt: 2, }}>
                                        <Divider>Template Values</Divider>
                                        <List dense sx={{ fontSize: '0.5rem', }}>
                                            {option.createdBy && <ListItem key={'createdBy'}><ListItemText primary={`Created By: ${option.createdBy}`} /></ListItem>}
                                            {!!parsedTemplate.Strategy?.length && <ListItem key={'strategy'}><ListItemText primary={`Strategy: ${jsonOrCommaSeparatedFormatter(parsedTemplate.Strategy)}`} /></ListItem>}
                                            {!!parsedTemplate.Book?.length && <ListItem key={'book'}><ListItemText primary={`Book: ${jsonOrCommaSeparatedFormatter(parsedTemplate.Book)}`} /></ListItem>}
                                            {parsedTemplate.Contract && <ListItem key={'contract'}><ListItemText primary={`Contract: ${parsedTemplate.Contract}`} /></ListItem>}
                                            {parsedTemplate.Transaction_Type && <ListItem key={'transactionType'}><ListItemText primary={`Transaction Type: ${parsedTemplate.Transaction_Type}`} /></ListItem>}
                                            {parsedTemplate.Transaction && <ListItem key={'transaction'}><ListItemText primary={`Transaction: ${parsedTemplate.Transaction}`} /></ListItem>}
                                            {parsedTemplate.Trader && <ListItem key={'trader'}><ListItemText primary={`Trader: ${parsedTemplate.Trader}`} /></ListItem>}
                                            {parsedTemplate.Counterparty && <ListItem key={'counterparty'}><ListItemText primary={`Counterparty: ${parsedTemplate.Counterparty}`} /></ListItem>}
                                            {parsedTemplate.timezone && <ListItem key={'timezone'}><ListItemText primary={`Timezone: ${renderStandardDayjsTimezones[parsedTemplate.timezone]}`} /></ListItem>}
                                            {parsedTemplate.Term && <ListItem key={'term'}><ListItemText primary={`Term: ${parsedTemplate.Term}`} /></ListItem>}
                                            {parsedTemplate.Financial_Type && <ListItem key={'financialType'}><ListItemText primary={`Financial Type: ${parsedTemplate.Financial_Type}`} /></ListItem>}
                                            {parsedTemplate.Market && <ListItem key={'market'}><ListItemText primary={`Market: ${parsedTemplate.Market}`} /></ListItem>}
                                            {parsedTemplate.Zone && <ListItem key={'zone'}><ListItemText primary={`Zone: ${parsedTemplate.Zone}`} /></ListItem>}
                                            {parsedTemplate.por && <ListItem key={'por'}><ListItemText primary={`POR: ${parsedTemplate.por}`} /></ListItem>}
                                            {parsedTemplate.pod && <ListItem key={'pod'}><ListItemText primary={`POD: ${parsedTemplate.pod}`} /></ListItem>}
                                            {parsedTemplate.Index && <ListItem key={'index'}><ListItemText primary={`Index: ${parsedTemplate.Index}`} /></ListItem>}
                                            {<ListItem key={'confirmed'}><ListItemText primary={`Confirmed: ${!!parsedTemplate.confirmed ? 'Yes' : 'No'}`} /></ListItem>}
                                            {parsedTemplate.Adder && <ListItem key={'adder'}><ListItemText primary={`Adder: ${parsedTemplate.adder ? parseFloat(parsedTemplate.adder).toFixed(2) : ''}`} /></ListItem>}
                                            {<ListItem key={'indexType'}><ListItemText primary={`Index Type: ${parsedTemplate.indexType ?? 'None'}`} /></ListItem>}
                                            {<ListItem key={'forecast'}><ListItemText primary={`Forecast : ${parsedTemplate.forecast ?? 'None'}`} /></ListItem>}
                                            {<ListItem key={'dynamic'}><ListItemText primary={`Dynamic: ${!!parsedTemplate.dynamic ? 'Yes' : 'No'}`} /></ListItem>}
                                        </List>
                                        <Divider />
                                    </Box>
                                }>
                                    <Box component='li' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography {...props} width='100%'>{option.name}</Typography>
                                        {allowDeleteTemplate(option) && <IconButton size='small' onClick={() => handleDeleteTemplate(option)}>
                                            <DeleteForever />
                                        </IconButton>}
                                    </Box>
                                </Tooltip>
                            );
                        }}
                    />}
                    label="Template: "
                    componentsProps={{
                        typography: {
                            sx: { marginRight: theme.spacing(1), }
                        }
                    }}
                />}
                {!isEdit && <AddNewTemplateButton />}
            </Stack>
            <ProfileEditorDialog
                open={openProfileEditor}
                closeDialog={() => setOpenProfileEditor(false)}
                handleClose={handleProfileUpdate}
                dropdownOptions={options}
                editMode={isEdit}
            />
            <Box component='form' onSubmit={handleSubmit(onSubmit, onErrors)} id={formId} >
                {childrenWithProps}
            </Box>
        </FormProvider>
    );
}

const formatDefaultForPercentageDropdown = (value) => {
    let parsedVal;
    try {
        parsedVal = JSON.parse(value);
    } catch (e) {
        if (Array.isArray(value)) {
            parsedVal = value;
        } else {
            const valArr = value?.split(',').map(v => v.trim()) ?? [];
            parsedVal = valArr.map(v => ({ label: v, percentage: Math.floor(100 / valArr.length), }));
        }
    }
    return parsedVal;
}
