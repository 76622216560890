import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { useCallback, useRef, useState, useMemo } from "react";
import { useFormContext } from 'react-hook-form';
import { useApi } from '../../useApi';
import { DealHistoryGrid } from "./DealHistoryGrid";
import { Toolbar } from "./Toolbar";

export const DealHistoryDialog = ({ open, onClose, dealId, }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [date, setDate] = useState(dayjs());
    const { watch, } = useFormContext();
    const timezone = watch('timezone');
    const { get, post, enqueueSnackbar, headers, apiUrlPrefix } = useApi();
    const [historyData, setHistoryData] = useState([]);
    const gridRef = useRef(null);
    const [loading, setLoading] = useState(false);

    function handleClose() {
        onClose();
        setSelectedRow(null);
    }

    async function fetchHistory(dateTime, direction = '') {
        setLoading(true);
        gridRef.current?.api.showLoadingOverlay();
        const dt = dayjs(dateTime ?? dayjs()).format('MM/DD/YYYY HH:mm');

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchDealHistory${direction}_v4`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${dt}`
            + `&parm=${dealId}`

        return get(url).then(response => {
            gridRef.current?.api.deselectAll();
            const newData = !!response?.data ? response.data : [];
            setHistoryData(newData);
            setLoading(false);
            return response;
        });
    }

    function handleDateChange(newDate) {
        setDate(newDate);
        fetchHistory(newDate);
    }

    function handleFetchPrevious() {
        fetchHistory(date, 'Previous').then((response) => {
            setDate(maxDate(response?.data ?? []) ?? dayjs());
        });
    };

    function handleFetchNext() {
        fetchHistory(earliestDate);
        setDate(dayjs(earliestDate));
    };

    const maxDate = useCallback((data) => {
        const max = data.reduce((max, current) => {
            const currentTimestamp = dayjs(current.DATE_TIME_STAMP_UTC);
            if (!max) return currentTimestamp;
            return currentTimestamp.isAfter(max) ? currentTimestamp : max;
        }, null);

        return max;
    }, []);

    const earliestDate = useMemo(() => {
        return historyData.reduce((earliest, current) => {
            const currentTimestamp = dayjs(current.DATE_TIME_STAMP_UTC);
            if (!earliest) return currentTimestamp;
            return currentTimestamp.isBefore(earliest) ? currentTimestamp : earliest;
        }, undefined);
    }, [historyData]);

    const handleRollback = useCallback(debounce(async () => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_RollbackDealProfile`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedRow.dealID}`
            + `&parm=${timezone}`

        return post(url, selectedRow.sparklineJson).then(response => {
            if (response?.status === 200) {
                enqueueSnackbar('Rollback successful', { variant: 'success' });
            }
        });
    }, 1000), [selectedRow, timezone]);

    function rollbackAndClose() {
        handleClose();
        handleRollback();
    }

    return (
        <Dialog
            open={!!open}
            onClose={handleClose}
            fullWidth
            maxWidth='xl'
        >
            <DialogTitle sx={{ py: 1, }}>
                <Stack spacing={5} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6'>Deal History for {dealId}</Typography>
                    <Toolbar
                        date={date}
                        onDateChange={handleDateChange}
                        disabled={loading}
                        handleFetchPrevious={handleFetchPrevious}
                        handleFetchNext={handleFetchNext}
                    />
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleClose} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '75vh', py: 0, px: 1, }}>
                <DealHistoryGrid
                    ref={gridRef}
                    data={historyData}
                    setSelectedRow={setSelectedRow}
                    fetchHistory={() => handleDateChange(dayjs())}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                <Box sx={{ flexGrow: 1, }} />
                <Tooltip title="Replace the deal's current profile with the selected profile from the past. Price will also be rolled back." arrow position="bottom">
                    <span>
                        <Button
                            startIcon={<RestoreIcon />}
                            onClick={rollbackAndClose}
                            color="primary"
                            variant='contained'
                            disabled={!selectedRow}
                        >
                            Rollback Profile & Price
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};
