import { AgGridReact } from "ag-grid-react"
import dayjs from "dayjs"
import isEqual from 'lodash/isEqual'
import { forwardRef, useCallback, useMemo, useRef } from "react"
import { useFormContext } from "react-hook-form"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { defaultColumnDef, defaultGridOptions } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { useApi } from "../../useApi"
import { useDashboardLayout } from "../useDashboardLayout"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { useRowDebounce } from "../useRowDebounce"
import { handleUpdateTotalRows, heatmapStyle, jsonOrCommaSeparatedFormatter, redPastMarkerFormatter, tooltipRenderer } from "../Utils"

export const UnscheduledMWsGrid = forwardRef(({ data, setSelectedRows, selectedRows, context, id, }, ref) => {
    const { columnTypes } = useColumnTypes();
    const paletteRef = useRef({ showHeatmap: false });
    const layoutStorageKey = `deal-rizz-scheduling-unscheduled-mws-grid-layout-${id}-${context.id}`;
    const { getValues } = useFormContext();
    const { post, headers, apiUrlPrefix, enqueueSnackbar, logAction } = useApi();
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);

    const handleRowModification = useCallback(async (updatedRow) => {

        let url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_ModifyDealMW_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${updatedRow.dealID}`
            + `&parm=${updatedRow.flowDate}`
            + `&parm=${updatedRow.flowDateTimeZone}`;

        if (updatedRow[27]) {
            enqueueSnackbar(`HE 27 detected and removed. MW: ${updatedRow[27].MW}, Price: ${updatedRow[27].MW}`, { variant: 'warning' });
            console.log('HE 27 detected and removed. MW: ', updatedRow[27].MW, 'Price: ', updatedRow[27].Price);
            logAction('HE 27 detected and removed', 'Position Management', updatedRow);
            delete updatedRow[27];
        }

        return post(url, updatedRow).then(response => {
            if (response.status === 200) {
                enqueueSnackbar("Position updated successfully", { variant: "success" });
            }
        });

    }, [enqueueSnackbar, headers, post, apiUrlPrefix, logAction]);

    const { batchRowUpdate } = useRowDebounce({ onTimeExpired: handleRowModification, });

    function handleCellValueChanged(event) {
        batchRowUpdate(event);
    }



    const baseColDefs = useMemo(() => ([
        {
            headerName: 'Deal ID',
            checkboxSelection: true,
            field: 'dealID',
            cellDataType: 'text',
        },
        {
            field: 'Date',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Deal_Token',
            headerName: 'Deal Name',
        },
        {
            field: 'Status',
            headerName: 'Deal Status',
            filter: 'agSetColumnFilter',
            filterParams: {
                // provide all values, even if days are missing in data!
                values: ['VOIDED', 'CONFIRMED', 'DRAFT']
            },
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Book);
            },
            headerName: 'Book',
        },
        {
            field: 'Counterparty',
        },
        {
            field: 'Contract',
        },
        {
            headerName: 'Firm/Non-Firm',
            field: 'FirmNonFirm',
        },
        {
            field: 'Index',
        },
        {
            field: 'Creator',
        },
        {
            field: 'Market',
        },
        {
            field: 'Zone',
            headerName: 'Zone',
        },
        {
            field: 'Hidden',
            headerName: 'Hidden',
            filterParams: {
                // provide all values, even if days are missing in data!
                values: ['Yes', 'No',]
            },
        },
        {
            field: 'por',
            headerName: 'POR',
        },
        {
            field: 'pod',
            headerName: 'POD',
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Strategy);
            },
            headerName: 'Strategy',
        },
        {
            field: 'Term',
        },
        {
            headerName: "Full Deal Profile",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialHide: true,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data?.dealProfileJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        {
            headerName: "Full Price Curve",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialHide: true,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data?.dealProfileJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.price ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.price ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Deal Position',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            flex: 3,
            minWidth: 200,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                const date = getValues('startDate');
                return [...Array.from({ length: 26 }).keys()].reduce((acc, next) => {
                    const hour = next + 1;

                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour] ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour] ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        {
            headerName: "2*",
            field: "2*",
            flex: 1,
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 55,
            chartDataType: 'series',
            type: 'numericColumn',
            // editable: params => !params.node.isRowPinned(),
            editable: false,
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            // cellStyle: params => heatmapStyle(params, paletteRef),
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            flex: 1,
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 55,
            // editable: params => !params.node.isRowPinned(),
            editable: false,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
        {
            field: 'Deal_Currency',
            headerName: 'Deal Currency',
        },
        {
            field: 'Total_Settlement',
            headerName: 'Settlement',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellDataType: 'number',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                }
                return value;
            }
        },
        {
            field: 'Transaction_Type',
            headerName: 'Transaction Type',
        },
        {
            field: 'Type_F_P',
            headerName: 'Transaction',
        },
    ]), [getValues]);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 2,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: context,
        baseColDefs,
        defaultColDef,
    });

    const gridContainerId = `deal-rizz-unscheduled-mws-grid-${id}-${context.id}`;

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(ref, paletteRef, `deal-rizz-unscheduled-mws-${id}-palette`, gridContainerId),
        ]
    }), [gridContainerId, id, ref]);

    function onGridReady() {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, [applyFilters]);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, colDefs[0]?.field);
    }, [colDefs]);

    const handleSelectionChanged = useCallback((params) => {
        const selectedRows = params.api?.getSelectedRows();
        setSelectedRows(selectedRows);
    }, [setSelectedRows]);

    const getRowId = useCallback((params) => {
        const { data, } = params;
        return `${data?.dealID}`;
    }, []);

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    const unselectFilteredRows = useCallback((api) => {
        const selectedNodes = api.getSelectedNodes();
        const displayedNodeIds = new Set();

        api.forEachNodeAfterFilter(node => {
            displayedNodeIds.add(node.id);
        });

        selectedNodes.forEach(node => {
            if (!displayedNodeIds.has(node.id)) {
                node.setSelected(false);
            }
        });
    }, []);

    function handleRowDataUpdated(params) {
        updateTotalRows(params);
        const currentSelection = ref.current.api.getSelectedRows();
        if (!isEqual(currentSelection, selectedRows)) {
            selectedRows.forEach(row => {
                const node = ref.current.api.getRowNode(row.dealID);
                if (node) {
                    node.setSelected(true);
                }
            });
        }
    }

    const handleFilterChanged = useCallback((params) => {
        updateTotalRows(params);
        const api = params.api;
        unselectFilteredRows(api);
    }, [updateTotalRows, unselectFilteredRows]);

    return (
        <AgGridContainer style={{ width: '100%', height: '100%' }} onMouseLeave={clearHighlighting} id={gridContainerId}>
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ height: '100%', width: '100%' }}
                rowData={data}
                getRowId={getRowId}
                ref={ref}
                gridOptions={gridOptions}
                onFilterChanged={handleFilterChanged}
                onCellValueChanged={handleCellValueChanged}
                stopEditingWhenCellsLoseFocus={true}
                onRowDataUpdated={handleRowDataUpdated}
                onSelectionChanged={handleSelectionChanged}
                rowSelection='multiple'
                rowMultiSelectWithClick
                overlayNoRowsTemplate="No rows. Change the start date in the ribbon to a time with deals."
                enableCharts
                columnDefs={styledColDefs}
                onFirstDataRendered={handleFirstDataRendered}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                sideBar={sideBar}
                onCellMouseOver={handleCellMouseOverHighlight}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    paletteToolPanel: PaletteToolPanel,
                }}
            />
        </AgGridContainer>
    );
});
