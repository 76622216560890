import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { CreateBookoutDialog } from '../CreateBookoutDialog';
import { RibbonButton } from '../Ribbon';

export const CreateBookoutButton = ({ expanded, selectedRow, getBookoutData, }) => {
    const [bookoutData, setBookoutData] = useState(null);
    const [openBookoutDialog, setOpenBookoutDialog] = useState(false);

    const handleClose = (event) => {
        event.stopPropagation();
        setOpenBookoutDialog(false);
    };

    const handleOpen = (event) => {
        event.stopPropagation();
        setBookoutData(getBookoutData());
        setOpenBookoutDialog(true);
    }

    return (
        <>
            <CreateBookoutDialog
                open={openBookoutDialog}
                closeDialog={handleClose}
                bookoutData={bookoutData}
            />
            <Tooltip title="Create a new bookout for the selected deal.">
                <span>
                    <RibbonButton
                        icon={<BookmarkAddIcon />}
                        onMouseDown={handleOpen}
                        color="primary"
                        variant='contained'
                        disabled={!selectedRow}
                        label={'Create Bookout'}
                        expanded={expanded}
                    />
                </span>
            </Tooltip>
        </>
    );
};
