import { useCallback, useMemo } from "react";

export const useLayoutFunctions = () => {
    const fields = useMemo(() => ['colId', 'field', 'width', 'hide', 'sort', 'order', 'pinned', 'headerName', 'sortIndex', 'rowGroup', 'rowGroupIndex', 'pivot', 'pivotIndex', 'aggFunc'], []);

    const captureDefLayout = useCallback((def) => {
        const defLayout = {};
        fields.forEach(field => {
            defLayout[field] = def[field];
        });
        return defLayout;
    }, [fields]);

    const applyDefLayout = useCallback((def, defLayout) => {
        const newDef = { ...def };
        fields.forEach(field => {
            if (!['field', 'headerName', 'colId'].includes(field)) { //always use the field, headerName, and colId from the code
                newDef[field] = defLayout[field];
            }
        });
        return newDef;
    }, [fields]);

    const captureLayout = useCallback((gridRef) => {
        const defs = gridRef.current.api.getColumnDefs();
        const state = defs.reduce((acc, def, i) => {
            const defLayout = captureDefLayout(def);
            defLayout.order = i;

            if (def.children) {
                defLayout.children = def.children.map(childDef => {
                    const childDefLayout = captureDefLayout(childDef);
                    childDefLayout.hide = childDef.hide ? childDef.hide : false;
                    return childDefLayout;
                });

                //set 'hide' to true if all children are hidden
                const allChildrenHidden = defLayout.children.every(child => child.hide);
                defLayout.hide = allChildrenHidden;
            } else {
                defLayout.hide = def.hide ? def.hide : false; //set undefined value to false so it displays properly after saving/loading
            }

            acc.push(defLayout);

            return acc;
        }, []);
        const filters = gridRef.current.api.getFilterModel();

        return {
            filters: filters,
            state: state,
        }
    }, [captureDefLayout]);

    const findMatchingDef = useCallback((def, layoutDefs) => {
        const matchKeys = ['field', 'colId', 'headerName'];
        return layoutDefs?.find(lyt => {
            for (const key of matchKeys) {
                if (def[key]) {
                    return def[key] === lyt[key];
                }
            }
            return false;
        });
    }, []);

    //apply saved layout to the col defs; recursively apply layout to children
    const applySavedDefs = useCallback((defs, layoutDefs = [], defaultDef) => {
        return defs.map(def => {
            let newDef = { ...defaultDef, ...def };
            const defLayout = findMatchingDef(def, layoutDefs);

            if (defLayout) {
                newDef = applyDefLayout(newDef, defLayout);
            }

            if (newDef.children) {
                newDef.children = applySavedDefs(newDef.children, defLayout?.children, defaultDef);
            }

            return newDef;
        });
    }, [applyDefLayout, findMatchingDef]);


    const applyLayout = useCallback((layout, baseColDefs, defaultDef) => {
        const defs = applySavedDefs(baseColDefs, layout?.state, defaultDef);

        const widthAdjustedDefs = defs.map(def => ({ //remove flex property if width is set to persist the users width setting
            ...def,
            flex: def.width ? undefined : def.flex,
        }));
        const sortedDefs = widthAdjustedDefs.sort((def1, def2) => def1.order - def2.order);

        return sortedDefs;
    }, [applySavedDefs]);

    const applyFilters = useCallback((gridRef, filters) => {
        gridRef.current.api.setFilterModel(filters);
        gridRef.current.api.onFilterChanged();
    }, []);

    return {
        captureLayout,
        captureDefLayout,
        applyLayout,
        applyFilters,
    };
};
