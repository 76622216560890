import { FormGroup, FormControlLabel, Box, Switch, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

export const PaletteToolPanel = ({ gridRef, paletteRef, storageKey, containerId, }) => {
    const savedSettings = JSON.parse(localStorage.getItem(storageKey)) ?? {};
    const [showMWHeatmap, setShowMWHeatmap] = useState(savedSettings.showMWHeatmap ?? false);
    const [showPriceHeatmap, setShowPriceHeatmap] = useState(savedSettings.showPriceHeatmap ?? false);
    const [showBorders, setShowBorders] = useState(savedSettings.showBorders ?? false);

    useEffect(() => {
        paletteRef.current = {
            showMWHeatmap,
            showPriceHeatmap,
            showBorders,
        };
        handleToggleBorders(showBorders);
    }, []);

    function handleToggleMWHeatmap() {
        setShowMWHeatmap(!showMWHeatmap);
        const newState = { ...paletteRef.current, showMWHeatmap: !showMWHeatmap };
        handleToggle(newState);
    }

    function handleTogglePriceHeatmap() {
        setShowPriceHeatmap(!showPriceHeatmap);
        const newState = { ...paletteRef.current, showPriceHeatmap: !showPriceHeatmap };
        handleToggle(newState);
    }

    function handleToggleBorders(toShow) {
        //use the container id to get the container div
        //toggle the ag-grid-column-borders class to show/hide borders
        const container = document.getElementById(containerId);
        if (container) {
            if (toShow) {
                container.classList.add('ag-grid-column-borders');
            } else {
                container.classList.remove('ag-grid-column-borders');
            }
        }

        setShowBorders(toShow);
        paletteRef.current = { ...paletteRef.current, showBorders: toShow, };

        //update local storage
        const currentlySaved = JSON.parse(localStorage.getItem(storageKey)) ?? {};
        localStorage.setItem(storageKey, JSON.stringify({ ...currentlySaved, showBorders: toShow, }));
    }

    function handleToggle(newState) {
        //eventually we should use the grid context instead of a separate ref
        paletteRef.current = newState;
        gridRef.current.api.refreshCells({ force: true, });
        localStorage.setItem(storageKey, JSON.stringify(newState));
    }

    return (
        <Box sx={{ display: 'flex', p: 1, flexDirection: 'column' }}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', p: 1, }}>
                <Typography variant='h6'>Cell Palette</Typography>
            </Box>

            <Stack spacing={2} sx={{ width: '100%', display: 'flex', p: 1, }}>
                <FormGroup>
                    <FormControlLabel
                        label='Show MW Heatmap'
                        control={
                            <Switch
                                checked={showMWHeatmap}
                                onMouseDown={handleToggleMWHeatmap}
                            />
                        }
                    />
                </FormGroup>

                <FormGroup>
                    <FormControlLabel
                        label='Show Price Heatmap'
                        control={
                            <Switch
                                checked={showPriceHeatmap}
                                onMouseDown={handleTogglePriceHeatmap}
                            />
                        }
                    />
                </FormGroup>

                {containerId && <FormGroup>
                    <FormControlLabel
                        label='Show Borders'
                        control={
                            <Switch
                                checked={showBorders}
                                onMouseDown={() => handleToggleBorders(!showBorders)}
                            />
                        }
                    />
                </FormGroup>}
            </Stack>
        </Box>
    );
};
