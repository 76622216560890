import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, ButtonBase, Collapse, Divider, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Children, cloneElement, useState } from "react";

export const Ribbon = ({ children, }) => {
    const [expanded, setExpanded] = useState(true);

    return (
        <RibbonContainer elevation={4}>
            {children.map(({ title, content, visible = true, }, index) => (
                <StyledCollapse key={title} in={visible} orientation="horizontal">
                    <RibbonGroup title={title} expanded={expanded}>{content}</RibbonGroup>
                    {index < children.length - 1 && <Divider orientation="vertical" variant='middle' flexItem />}
                </StyledCollapse>
            ))}
            <Tooltip title={expanded ? 'Minimize ribbon' : 'Expand ribbon'}>
                <ExpandMore
                    onMouseDown={() => setExpanded(e => !e)}
                    expand={expanded}
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </Tooltip>
        </RibbonContainer>
    );
}

const RibbonContainer = styled(Paper)(({ theme, }) => ({
    position: 'relative',
    display: 'flex',
    marginBottom: theme.spacing(1),
    //marginTop: theme.spacing(1),
    borderRadius: '5px',
    width: '100%',
}));

const RibbonGroup = ({ title, expanded, children }) => {
    const childrenWithProps = Children.map(children, child =>
        cloneElement(child, { expanded })
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%',
                px: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: expanded ? 'start' : 'center',
                    alignItems: 'center',
                    flexGrow: 1,
                }}
            >
                {childrenWithProps}
            </Box>
            {expanded && <Typography variant="caption" align="center">
                {title}
            </Typography>}
        </Box>
    );
};

export const RibbonButton = ({ label, icon, expanded, ...buttonProps }) => {
    return (
        <StyledButtonBase
            {...buttonProps}
            sx={{
                color: !!buttonProps.disabled ? 'grey.500' : 'primary.main',
                transition: 'background-color 0.3s',
                '&:hover': {
                    backgroundColor: 'action.hover',
                },
                ...buttonProps.sx,
                maxWidth: '100px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    height: '80%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {icon}
            </Box>
            {expanded && <Typography variant="caption" sx={{ mt: 1 }} lineHeight='1.2em'>
                {label}
            </Typography>}
        </StyledButtonBase>
    );
};

export const RibbonGroupContainer = (props) => {
    const { children, sx, ...containerProps } = props;

    return (
        <Stack
            direction='row'
            spacing={1}
            sx={{
                display: 'flex',
                alignItems: 'center',
                pt: 2,
                pb: 1,
                ...sx,
            }}
            {...containerProps}
        >
            {children}
        </Stack>
    );
}

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5),
    borderRadius: '5px',
    maxWidth: '100px',
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
    '& .MuiCollapse-wrapperInner': {
        display: 'flex',
        flexDirection: 'row',
    },
}));


const ExpandMore = styled((props) => {
    const { expand, theme, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    borderRadius: '2px',
    position: 'absolute',
    height: '2em',
    width: '2em',
    fontSize: '1.5rem',
    bottom: '2px',
    right: '4px',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));

