import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsIcon from '@mui/icons-material/Directions';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useState, useRef } from 'react';
import CornerRibbon from 'react-corner-ribbon';
import { useNavigate } from "react-router";
import { userGroups } from '../../authConfig.js';
import GroupGuard from '../Guards/GroupGuard.js';
import { Drawer } from '../StyledDrawer.js';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CellTowerIcon from '@mui/icons-material/CellTower';

export default function TransNowAppMenu({ selected }) {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const timerRef = useRef(null);

	function handleSelection(view) {
		navigate(`/transnow/${view}`);
	}

	const handleMouseEnter = () => {
		timerRef.current = setTimeout(() => {
			setOpen(true);
		}, 200);
	};

	const handleMouseLeave = () => {
		clearTimeout(timerRef.current); // Clear the timer if the mouse leaves
		setOpen(false);
	};

	return (
		<Drawer
			variant="permanent"
			open={open}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<List>
				<GroupGuard authenticatedOnly>
					<Tooltip title="View information about TransNow." arrow placement="right">
						<ListItemButton
							selected={selected === 'transnowhelp'}
							onClick={() => handleSelection('transnowhelp')}>
							<ListItemIcon>
								<QuestionMarkIcon />
							</ListItemIcon>
							<ListItemText primary="TransNow Help" />
						</ListItemButton>
					</Tooltip>
				</GroupGuard>
				<GroupGuard authenticatedOnly>
					<Tooltip title="See the details of the TSRs recently queried from OASIS." arrow placement="right">
						<ListItemButton
							selected={selected === 'tsractivity'}
							onClick={() => handleSelection('tsractivity')}>
							<ListItemIcon>
								<LocalActivityIcon />
							</ListItemIcon>
							<ListItemText primary="TSR Detail" />
						</ListItemButton>
					</Tooltip>
				</GroupGuard>

				<Tooltip title="Create and adjust tags." arrow placement="right">
					<ListItemButton
						selected={selected === 'tagreport'}
						onClick={() => handleSelection('tagreport')}>
						<ListItemIcon>
							<LocalOfferIcon />
						</ListItemIcon>
						<ListItemText primary="Tagging" />
					</ListItemButton>
				</Tooltip>

				<ListItemButton
					selected={selected === 'transmissionposition'}
					onClick={() => handleSelection('transmissionposition')}>
					<ListItemIcon>
						<CellTowerIcon />
					</ListItemIcon>
					<ListItemText primary="Trans Position" />
				</ListItemButton>

				<Divider textAlign='left'>{open ? 'Day Ahead' : ''}</Divider>
				{/*<ListItemButton> //ecz 10/7 label is part of the divider above, don't need these functionless buttons
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText primary="Day Ahead" />
        </ListItemButton>*/}
				{/* <Tooltip title="Submit an original request for Network Transmission to OASIS." arrow placement="right">
          <ListItemButton
            selected={selected === 'originalrequests'}
            onClick={() => handleSelection('originalrequests')}>
            <ListItemIcon>
              <PodcastsIcon />
            </ListItemIcon> 
            <ListItemText primary="Network Trans" />
          </ListItemButton>
        </Tooltip> */}
				<GroupGuard groups={[userGroups.admins]}>
					<div style={{ position: 'relative' }}>
						{open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
						<Tooltip title="Submit an orignal request for a new TSR." arrow placement="right">
							<ListItemButton
								selected={selected === 'originalrequests'}
								onClick={() => handleSelection('originalrequests')}>
								<ListItemIcon>
									<RequestPageIcon />
								</ListItemIcon>
								<ListItemText primary="Original Reqs" />
							</ListItemButton>
						</Tooltip>
					</div>
				</GroupGuard>
				<GroupGuard groups={[userGroups.admins]}>
					<div style={{ position: 'relative' }}>
						{open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
						<Tooltip title="Day ahead trading interface." arrow placement="right">
							<ListItemButton
								selected={selected === 'dayahead'}
								onClick={() => handleSelection('dayahead')}>
								<ListItemIcon>
									<AssignmentIndIcon />
								</ListItemIcon>
								<ListItemText primary="Resale/Redirects" />
							</ListItemButton>
						</Tooltip>
					</div>
				</GroupGuard>
				<Tooltip title="Day ahead interface for redirecting transmission." arrow placement="right">
					<ListItemButton
						selected={selected === 'dayaheadredirects'}
						onClick={() => handleSelection('dayaheadredirects')}>
						<ListItemIcon>
							<DirectionsIcon />
						</ListItemIcon>
						<ListItemText primary="Redirects" />
					</ListItemButton>
				</Tooltip>
				<Divider textAlign='left'>{open ? 'Real Time' : ''}</Divider>
				{/*<ListItemButton>  //ecz 10/7 label is part of the divider above, don't need these functionless buttons
          <ListItemIcon>
            <QueryBuilderIcon />
          </ListItemIcon>
          <ListItemText primary="Real Time" />
      </ListItemButton>*/}
				<Tooltip title="Real time original requests made simple." arrow placement="right">
					<ListItemButton
						selected={selected === 'realtimeoriginalrequests'}
						onClick={() => handleSelection('realtimeoriginalrequests')}>
						<ListItemIcon>
							<AddAlarmIcon />
						</ListItemIcon>
						<ListItemText primary="Original Reqs" />
					</ListItemButton>
				</Tooltip>
				{/* <Tooltip title="Bulk submit deirects as new TSRs." arrow placement="right">
          <ListItemButton
            selected={selected === 'redirects'}
            onClick={() => handleSelection('redirects')}>
            <ListItemIcon>
              <DirectionsIcon />
            </ListItemIcon> 
            <ListItemText primary="Redirects" />
          </ListItemButton>
        </Tooltip> */}
				<Tooltip title="Get in to some real time trading action." arrow placement="right">
					<ListItemButton
						selected={selected === 'realtime'}
						onClick={() => handleSelection('realtime')}>
						<ListItemIcon>
							<DashboardIcon />
						</ListItemIcon>
						<ListItemText primary="Resale/Redirect" />
					</ListItemButton>
				</Tooltip>
				<GroupGuard groups={[userGroups.ppwtrans]}>
					<Tooltip title="Reserve bundled PPW trans and receive discounted 2nd and 3rd legs...." arrow placement="right">
						<ListItemButton
							selected={selected === 'ppwtrans'}
							onClick={() => handleSelection('ppwtrans')}>
							<ListItemIcon>
								<MultipleStopIcon />
							</ListItemIcon>
							<ListItemText primary="PPW Trans" />
						</ListItemButton>
					</Tooltip>
				</GroupGuard>
			</List>
			<Divider />
			<List>
				<GroupGuard groups={[userGroups.dayaheadredirects]}>
					<Tooltip title="View and edit your transaction alerts." arrow placement="right">
						<ListItemButton
							selected={selected === 'transactionqueue'}
							onClick={() => handleSelection('transactionqueue')}>
							<ListItemIcon>
								<TrackChangesIcon />
							</ListItemIcon>
							<ListItemText primary="Trans Q" />
						</ListItemButton>
					</Tooltip>
				</GroupGuard>
				<GroupGuard groups={[userGroups.transnowauditgrid]}>
					<Tooltip title="See the audit log for all the OASIS transactions processed by your organization." arrow placement="right">
						<ListItemButton
							selected={selected === 'audit'}
							onClick={() => handleSelection('audit')}>
							<ListItemIcon>
								<ReceiptLongIcon />
							</ListItemIcon>
							<ListItemText primary="Transaction Log" />
						</ListItemButton>
					</Tooltip>
				</GroupGuard>
				<Tooltip title="Use the tariff prices default settings utility." arrow placement="right">
					<ListItemButton
						selected={selected === 'tariffprices'}
						onClick={() => handleSelection('tariffprices')}>
						<ListItemIcon>
							<AttachMoneyIcon />
						</ListItemIcon>
						<ListItemText primary="Tariff Prices" />
					</ListItemButton>
				</Tooltip>



			</List>
		</Drawer>
	);
}
