import { ListItemIcon } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export const DragHandle = ({ listeners, attributes, isDragging }) => {
    return (
        <ListItemIcon
            {...listeners}
            {...attributes}
            sx={{
                cursor: 'grab',
                ...(isDragging && {
                    cursor: 'grabbing',
                }),
            }}
        >
            <DragIndicatorIcon />
        </ListItemIcon>
    );
}
