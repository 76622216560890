import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { userGroups } from '../../authConfig';
import '../../styles/dealRizzStyles.css';
import { TSRActivity } from '../../views';
import CriteriaProvider from '../CriteriaContext/CriteriaProvider';
import FlexCollapse from '../FlexCollapse';
import { DivGuard } from '../Guards/DivGuardFlex';
import { PathMonitor } from '../PathMonitor/PathMonitor';
import { CheckoutDashboard } from './Checkout/CheckoutDashboard';
import { CheckoutWorksheetDashboard } from './CheckoutWorksheet/CheckoutWorksheetDashboard';
import { LookupValuesProvider } from './DealEntry/LookupContext';
import { DealPositionDashboard } from './DealPosition/DealPositionDashboard';
import { DealSummaryDashboard } from './DealSummary/DealSummaryDashboard';
import { Discrepancies } from './Discrepancies/Discrepancies';
import { Forecast } from './Forecast/Forecast';
import { Help } from './Help';
import { IndexPricingDashboard } from './IndexPricing/IndexPricingDashboard';
import { LMPs } from './LMPs/LMPs';
import Menu from './Menu';
import { PathBuilder } from './PathBuilder/PathBuilder';
import { PositionManagementDashboard } from './PositionManagement/PositionManagementDashboard';
import { ScheduleSummaryDashboard } from './ScheduleSummary/ScheduleSummaryDashboard';
import { SchedulingDashboard } from './Scheduling/SchedulingDashboard';
import { TaggingProvider } from './TagReport/TaggingContext';
import { TagReportDashboard } from './TagReport/TagReportDashboard';
import { TransDealSummaryDashboard } from './TransmissionDealSummary/TransDealSummaryDashboard';
import { TransmissionPositionDashboard } from './TransmissionPosition/TransmissionPositionDashboard';
import { UnscheduledMWsDashboard } from './UnscheduledMWs/UnscheduledMWsDashboard';
import { ReviewDashboard } from './Review/ReviewDashboard';

const viewStorageKey = 'deal-rizz-saved-view';

export const DealRizz = () => {
    const savedView = localStorage.getItem(viewStorageKey);
    const params = useParams();
    const view = params.view;
    const navigate = useNavigate();

    useEffect(() => {
        if (!view) {
            const newView = savedView ?? 'lmps';
            navigate(`/dealrizz/${newView}`);
        }
    }, [view, savedView]);

    return (
        <CriteriaProvider>
            <LookupValuesProvider>
                <Box sx={{ display: 'flex', height: '100%', width: '100%', overflow: 'hidden', }}>
                    <Menu view={view} />
                    <DivGuard groups={[userGroups.dealrizz]}>
                        <Outlet />
                    </DivGuard>
                </Box>
            </LookupValuesProvider>
        </CriteriaProvider>
    )
}

export const DealRizzViews = () => {
    const [loadedViews, setLoadedViews] = useState({});
    const params = useParams();
    const view = params.view;

    useEffect(() => {
        if (view && !loadedViews[view]) {
            setLoadedViews({
                ...loadedViews,
                [view]: true
            })
        }
    }, [view]);

    useEffect(() => {
        if (view) {
            localStorage.setItem(viewStorageKey, view);
        }
    }, [view])

    return (
        <Box
            sx={{
                p: '0px',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                //overflowY: view === 'help' ? 'hidden' : 'auto',
                overflowY: 'auto',
            }}
        >
            {(loadedViews['pathbuilder']) && <FlexCollapse in={view === 'pathbuilder'}>
                <DivGuard groups={[userGroups.dealrizzplatinum]}>
                    <PathBuilder />
                </DivGuard>
            </FlexCollapse>}
            {loadedViews['tsractivity'] && <FlexCollapse in={view === 'tsractivity'}>
                <TSRActivity visible={view === 'tsractivity'} application='dealrizz' />
            </FlexCollapse>}
            {loadedViews['lmps'] && <FlexCollapse in={view === 'lmps'}>
                <DivGuard groups={[userGroups.dealrizzplatinum]}>
                    <LMPs visible={view === 'lmps'} />
                </DivGuard>
            </FlexCollapse>}
            {loadedViews['transactionqueue'] && <FlexCollapse in={view === 'transactionqueue'}>
                <PathMonitor application={'dealrizz'} />
            </FlexCollapse>}
            {loadedViews['positionreport'] && <FlexCollapse in={view === 'positionreport'}>
                <DealPositionDashboard visible={view === 'positionreport'} />
            </FlexCollapse>}
            {loadedViews['transmissionposition'] && <FlexCollapse in={view === 'transmissionposition'}>
                <TransmissionPositionDashboard visible={view === 'transmissionposition'} />
            </FlexCollapse>}
            {loadedViews['unscheduledmws'] && <FlexCollapse in={view === 'unscheduledmws'}>
                <UnscheduledMWsDashboard visible={view === 'unscheduledmws'} />
            </FlexCollapse>}
            {loadedViews['dealsummary'] && <FlexCollapse in={view === 'dealsummary'}>
                <DealSummaryDashboard visible={view === 'dealsummary'} />
            </FlexCollapse>}
            {loadedViews['schedulesummary'] && <FlexCollapse in={view === 'schedulesummary'}>
                <ScheduleSummaryDashboard visible={view === 'schedulesummary'} />
            </FlexCollapse>}
            {loadedViews['indexdailyprice'] && <FlexCollapse in={view === 'indexdailyprice'}>
                <IndexPricingDashboard visible={view === 'indexdailyprice'} />
            </FlexCollapse>}
            {loadedViews['transmissiondealsummary'] && <FlexCollapse in={view === 'transmissiondealsummary'}>
                <TransDealSummaryDashboard visible={view === 'transmissiondealsummary'} />
            </FlexCollapse>}
            {loadedViews['discrepancies'] && <FlexCollapse in={view === 'discrepancies'}>
                <Discrepancies />
            </FlexCollapse>}
            {loadedViews['tagreport'] && <FlexCollapse in={view === 'tagreport'}>
                <TaggingProvider>
                    <TagReportDashboard visible={view === 'tagreport'} />
                </TaggingProvider>
            </FlexCollapse>}
            {loadedViews['forecast'] && <FlexCollapse in={view === 'forecast'}>
                <Forecast />
            </FlexCollapse>}
            {loadedViews['scheduling'] && <FlexCollapse in={view === 'scheduling'}>
                <SchedulingDashboard visible={view === 'scheduling'} />
            </FlexCollapse>}
            {loadedViews['positionmanagement'] && <FlexCollapse in={view === 'positionmanagement'}>
                <PositionManagementDashboard visible={view === 'positionmanagement'} />
            </FlexCollapse>}
            {loadedViews['checkoutworksheet'] && <FlexCollapse in={view === 'checkoutworksheet'}>
                <DivGuard groups={[userGroups.dealrizzplatinum]}>
                    <CheckoutWorksheetDashboard visible={view === 'checkoutworksheet'} />
                </DivGuard>
            </FlexCollapse>}
            {loadedViews['help'] && <FlexCollapse in={view === 'help'}>
                <Help />
            </FlexCollapse>}
            {loadedViews['checkout'] && <FlexCollapse in={view === 'checkout'}>
                <CheckoutDashboard visible={view === 'checkout'} />
            </FlexCollapse>}
            {loadedViews['review'] && <FlexCollapse in={view === 'review'}>
                <ReviewDashboard visible={view === 'review'} />
            </FlexCollapse>}
        </Box>
    )
}

export const viewTitles = {
    pathbuilder: 'Path Builder',
    tsractivity: 'TSR Activity',
    lmps: 'LMPs',
    transactionqueue: 'Transaction Queue',
    dealentry: 'Deal Entry',
    positionreport: 'Deal Positions',
    transmissionposition: 'Trans Position',
    unscheduledmws: 'Unscheduled MWs',
    dealsummary: 'Deal Summary',
    schedulesummary: 'Schedule Summary',
    indexdailyprice: 'Index Daily Price',
    transmissiondealsummary: 'Transmission Deal Summary',
    discrepancies: 'Discrepancies',
    tagreport: 'Tagging',
    forecast: 'Forecast',
    scheduling: 'Scheduling',
    positionmanagement: 'Position Management',
    checkoutworksheet: 'Checkout Worksheet',
    checkout: 'Checkout',
    help: 'Help',
    review: 'Review',
};

