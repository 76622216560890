import { RibbonGroupContainer } from "../../Ribbon";
import { EditDealButton } from "../../RibbonButtons";
import { CreateBookoutButton } from "../../RibbonButtons/CreateBookoutButton";
import { FlagScheduleButton } from "../../RibbonButtons/FlagScheduleButton";

export const ActionsGroup = ({ expanded, getBookoutData, selectedData, onFlagStatusChanged, }) => {
    const toFlag = !selectedData || selectedData.Schedule_Status === 'CONFIRMED';

    return (
        <RibbonGroupContainer sx={{ alignItems: 'start' }}>

            <CreateBookoutButton
                expanded={expanded}
                getBookoutData={getBookoutData}
                selectedRow={selectedData}
            />

            <FlagScheduleButton
                expanded={expanded}
                scheduleId={selectedData?.scheduleID}
                toFlag={toFlag}
                onFlagStatusChanged={onFlagStatusChanged}
            />

            <EditDealButton
                expanded={expanded}
                disabled={!selectedData}
                deal={selectedData}
                label={'Edit Deal'}
            />
        </RibbonGroupContainer>
    )
}
