import { DealEntry } from "./DealEntry";
import { Dialog, DialogContent, DialogTitle, Box, IconButton, Tooltip, CircularProgress, Typography, Stack, Slide } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupGuard from "../../Guards/GroupGuard";
import { userGroups } from "../../../authConfig";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useState, useRef } from "react";
import { Config } from "./Config";
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material';

export const DealEntryDialog = ({ open, handleClose, handleSave, dealToEdit, loading = false }) => {
    const [viewConfig, setViewConfig] = useState(false);
    const contentContainerRef = useRef(null);
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='lg'
            ref={contentContainerRef}
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle sx={{ pb: 0, }}>
                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    {viewConfig && <Tooltip title='Back' placement='right'>
                        <IconButton onClick={() => setViewConfig(false)} size='large'>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Tooltip>}
                    <Box sx={{ flexGrow: 1, }} />
                    <GroupGuard groups={[userGroups.tenantAdmin]}>
                        <IconButton onClick={() => setViewConfig(v => !v)} size='large'>
                            <SettingsIcon />
                        </IconButton>
                    </GroupGuard>
                    <IconButton onClick={handleClose} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
                {!loading && <Slide in={viewConfig} direction='left' container={contentContainerRef.current}>
                    <div style={{ display: (viewConfig && !loading) ? 'flex' : 'none', flexGrow: 1, padding: theme.spacing(1) }}>
                        <Config hide={() => setViewConfig(false)} />
                    </div>
                </Slide>}
                {!loading && <Slide in={!viewConfig} direction='right' container={contentContainerRef.current}>
                    <div style={{ display: !viewConfig ? 'flex' : 'none', flexGrow: 1 }}>
                        <DealEntry handleCancel={handleClose} handleSaveAndClose={handleSave} dealToEdit={dealToEdit} />
                    </div>
                </Slide>}
                <LoadingOverlay visible={loading} />
            </DialogContent>
        </Dialog>
    );
};

const LoadingOverlay = ({ visible, }) => {
    return (
        <OverlayContainer visible={visible}>
            <CircularProgress />&nbsp;&nbsp;
            <Typography align='center' color='primary' variant='h6'>Loading...</Typography>
        </OverlayContainer>
    );
}

const OverlayContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'visible',
})(({ theme, visible }) => ({
    //position: 'absolute',
    //top: '45%',
    //left: 0,
    width: '100%',
    height: '35vh',
    display: visible ? 'flex' : 'none',
    //flexGrowth: 1,
    justifyContent: 'center',
    alignItems: 'center',
}));
