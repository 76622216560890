import { useRef, useMemo, useState, useCallback, forwardRef, useImperativeHandle, useEffect, } from "react"
import { Box } from "@mui/material"
import dayjs from "dayjs"
import { useApi } from "../../useApi"
import { useDashboardFetch } from "../useDashboardFetch"
import { DealPositionRibbon } from "./Ribbon/DealPositionRibbon"
import { ViewContextProvider } from "../ViewContextProvider"
import * as yup from 'yup';
import { useLayoutFunctions } from "../../useLayoutFunctions"
import { DealPositionGrid } from "./DealPositionGrid"
import { isLongDay } from "../Utils"

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    date: yup.date().required('Date is required'),
});

export const DealPositionView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-position-report-grid-layout-${view.id}`;
    const gridRef = useRef();
    const { headers, logAction, } = useApi();
    const [selectedRow, setSelectedRow] = useState();
    const toolbarFormId = `deal-rizz-position-report-toolbar-${view.id}`;
    const { captureLayout } = useLayoutFunctions();

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        ...view,
        date: dayjs(),
        showMWColumns: view.showMWColumns ?? true,
        showPriceColumns: view.showPriceColumns ?? false,
    }), [view]);

    const getRowId = useCallback(({ data }) => data.dealID, []);

    const dataFormatter = useCallback((data) => ({
        userGuid: headers.userGuid,
        date: dayjs(data.date).format('MM/DD/YYYY'),
        timezone: data.timezone,
    }), [headers]);

    const { loadData, applyData, fetchData, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_fetchDealPositionReport_v9',
        fetchParamKeys: ['userGuid', 'date', 'timezone'],
        gridRef,
        getRowId,
        dataFormatter,
    });

    function toggleExtraHourColBasedOnDate(date) {
        const isLong = isLongDay(date);
        gridRef.current?.columnApi?.setColumnVisible('2*.MW', isLong);
        gridRef.current?.columnApi?.setColumnVisible('2*.Price', isLong);
    }

    const handleFetchData = useCallback(async (data) => {
        logAction('User fetched Position Report Data', 'Position Report', data);
        loadData(data).then((response) => {
            updateGridData(response?.data);
        });
        toggleExtraHourColBasedOnDate(data.date);
    }, [headers]);

    const formatData = useCallback((data) => {
        return data.map(row => {
            const profile = JSON.parse(row.profileJson ?? '[]');
            const formatted = profile.reduce((acc, next) => {
                const MW = (!!next.MW || next.MW === 0) ? parseFloat(parseFloat(next.MW).toFixed(2)) : null;
                const Price = (!!next.price || next.price === 0) ? parseFloat(parseFloat(next.price).toFixed(2)) : null;
                const dstHour = next.length > 1 && parseInt(next.HourOfDay) <= 3;
                const colKey = dstHour ? `${next.HourOfDay}*` : next.HourOfDay; //the 'length' is how we detect an additional hour due to DST
                acc[colKey] = {
                    MW,
                    Price,
                };
                return acc;
            }, {});
            return {
                ...row,
                ...formatted,
            };
        });
    }, []);

    const updateGridData = useCallback((data) => {
        if (data) {
            const formatted = formatData(data);

            applyData({
                data: formatted,
            });
        }
    }, [applyData, formatData]);

    const silentUpdate = useCallback(async (data) => {
        return fetchData(data).then((response) => {
            updateGridData(response?.data);
        });
    }, [fetchData, updateGridData]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    const toggleHourlyColumns = useCallback((showMw, showPrice, date) => {
        const hours = Array.from({ length: 26 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(`${i}`);

            if (i === 2 && isLongDay(date)) {
                acc.push('2*');
            }

            return acc;
        }, []);

        hours.forEach((hour) => {
            gridRef.current?.columnApi?.setColumnVisible(`${hour}.MW`, showMw);
            gridRef.current?.columnApi?.setColumnVisible(`${hour}.Price`, showPrice);
        });

    }, [gridRef]);

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box className='flex-column'>
                <DealPositionRibbon
                    toolbarFormId={toolbarFormId}
                    handleFetchData={handleFetchData}
                    gridRef={gridRef}
                    selectedRow={selectedRow}
                    toggleHourlyColumns={toggleHourlyColumns}
                />
                <DealPositionGrid
                    setSelectedRow={setSelectedRow}
                    getRowId={getRowId}
                    layoutStorageKey={layoutStorageKey}
                    ref={gridRef}
                    silentUpdate={silentUpdate}
                    toggleHourlyColumns={toggleHourlyColumns}
                />
            </Box>
        </ViewContextProvider>
    )
});
