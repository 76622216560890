import ExpandMore from "@mui/icons-material/ExpandMore";
import { Accordion, accordionClasses, AccordionDetails, accordionDetailsClasses, AccordionSummary, Box, Stack, Typography, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import axios from "axios";
import dayjs from "dayjs";
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState, useEffect, } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import * as yup from 'yup';
import FlexCollapse from "../../FlexCollapse";
import { useApi } from "../../useApi";
import { useLayoutFunctions } from "../../useLayoutFunctions";
import { diffData } from "../Utils";
import { ViewContextProvider } from "../ViewContextProvider";
import { CounterpartyGrid } from "./CounterpartyGrid";
import { DailyGrid } from "./DailyGrid";
import { HourlyGrid } from "./HourlyGrid";
import { HourlyToolbar } from "./HourlyToolbar";
import { CheckoutRibbon } from "./Ribbon/CheckoutRibbon";

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    startDate: yup.date().required('Start Date is required'),
    stopDate: yup.date().required('Stop Date is required'),
    counterparty: yup.string().required('Counterparty is required'),
    transaction: yup.string().required('Transaction is required'),
    book: yup.string().required('Book is required'),
});

export const CheckoutView = forwardRef(({ view, }, ref) => {
    const hourlyGridRef = useRef();
    const dailyGridRef = useRef();
    const counterpartyGridRef = useRef();
    const toolbarFormId = `checkout-toolbar-form-${view.id}`;
    const { headers, apiUrlPrefix, handleErrorResponse } = useApi();
    const { captureLayout } = useLayoutFunctions();
    const accordionStateKey = `${view.id}-checkoutAccordionState`;
    const savedAccordionState = JSON.parse(localStorage.getItem(accordionStateKey)) ?? [true, true,];
    const [expandSummary, setExpandSummary] = useState(!!savedAccordionState[0]);
    const [expandHourly, setExpandHourly] = useState(!!savedAccordionState[1]);
    const [pivotHours, setPivotHours] = useState(!!view.pivotHours);
    const counterpartyAbortRef = useRef(new AbortController());
    const dailyAbortRef = useRef(new AbortController());
    const theme = useTheme();
    const topPanelRef = useRef();
    const bottomPanelRef = useRef();
    const panelGroupRef = useRef();
    const [selectedHourlyData, setSelectedHourlyData] = useState();

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        counterparty: 'All',
        transaction: 'All',
        book: 'All',
        ...view,
        startDate: dayjs().startOf('month'),
        stopDate: dayjs(),
    }), [view]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureAllLayouts(),
        };
    });

    function captureAllLayouts() {
        return {
            hourly: captureLayout(hourlyGridRef),
            daily: captureLayout(dailyGridRef),
            counterparty: captureLayout(counterpartyGridRef),
        }
    }

    const getCounterpartyRowId = useCallback((params) => {
        const { data, } = params;
        return `${data.Counterparty}-${data.Transaction}-${data.Book}`;
    }, []);

    const getDailyRowId = useCallback((params) => {
        const { data, } = params;
        return `${data.date}-${data.Transaction}`;
    }, []);

    const getHourlyRowId = useCallback((params) => {
        const { data, } = params;
        return `${data.dealID}-${data.scheduleID}-${data['Transaction Type']}-${data.Transaction}-${data['Flow Date']}-${data.HE}`;
    }, []);

    const applyData = useCallback((ref, data, getRowId) => {
        if (data && ref.current) {

            //clear old data
            const oldData = [];
            ref.current?.api.forEachLeafNode(node => {
                oldData.push(node.data);
            });

            const { toAdd, toUpdate, toDelete, } = diffData(data, oldData, (data) => getRowId({ data, }));
            ref.current.api.applyTransaction({ add: toAdd, update: toUpdate, remove: toDelete });
        }
    }, [])

    function cancelPreviousFetch(ref) {
        ref.current.abort();
        ref.current = new AbortController();
    }

    const fetchCounterpartyData = useCallback(async (data) => {
        counterpartyGridRef.current?.api?.deselectAll();
        counterpartyGridRef.current?.api?.showLoadingOverlay();
        dailyGridRef.current?.api?.deselectAll();
        cancelPreviousFetch(dailyAbortRef);
        cancelPreviousFetch(counterpartyAbortRef);
        const { counterparty, startDate, stopDate, timezone, transaction, book, } = data;
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchCounterpartyData_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${counterparty}`
            + `&parm=${transaction}`
            + `&parm=${book}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate).format('MM/DD/YYYY')}`
            + `&parm=${timezone}`

        return axios.get(url, { headers, signal: counterpartyAbortRef.current.signal }).catch(err => handleErrorResponse(err, url));
    }, [headers, apiUrlPrefix, handleErrorResponse]);

    const fetchDailyData = useCallback(async (data) => {
        dailyGridRef.current?.api?.deselectAll();
        dailyGridRef.current?.api?.showLoadingOverlay();
        cancelPreviousFetch(dailyAbortRef);
        const { counterparty, startDate, stopDate, timezone, transaction, book, } = data;
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchDailyDealData_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${counterparty}`
            + `&parm=${transaction}`
            + `&parm=${book}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate).format('MM/DD/YYYY')}`
            + `&parm=${timezone}`

        return axios.get(url, { headers, signal: dailyAbortRef.current.signal }).catch(err => handleErrorResponse(err, url));
    }, [headers, apiUrlPrefix, handleErrorResponse]);

    const pendingRequestsRef = useRef({});
    const fetchHourlyData = useCallback(async (data) => {
        if (pendingRequestsRef.current[data.date]) {
            pendingRequestsRef.current[data.date].cancel();
        }

        // Create a new CancelToken
        const source = axios.CancelToken.source();

        // Store the cancel function so we can cancel this request later
        pendingRequestsRef.current[data.date] = source;

        const { counterparty, date, timezone, transaction, } = data;
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchHourlyDealData_v3`
            + `&parm=${headers.userGuid}`
            + `&parm=${counterparty}`
            + `&parm=${transaction}`
            + `&parm=${dayjs(date).format('MM/DD/YYYY')}`
            + `&parm=${timezone}`

        return axios.get(url, { headers, cancelToken: source.token }).catch(err => handleErrorResponse(err, url));
    }, [headers, apiUrlPrefix, handleErrorResponse]);

    const hours = useMemo(() => {
        return Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(`${i}`);
            return acc;
        }, []);
    }, []);

    const isNullOrUndefined = (value) => value === null || value === undefined;

    const rollUpData = useCallback((data) => {
        const formatted = data.reduce((acc, item = {}) => {
            const key = String(item.dealID) + String(item.scheduleID) + item['Tag Code'] + item['Flow Date'];
            const existing = acc.get(key) ?? {};
            const updated = {
                ...existing,
                ...item,
                HE: 0,
                [`${item.HE}`]: {
                    scheduleMW: item.scheduleMW,
                    price: item.price,
                    dealMW: item.dealMW,
                    tagMW: item.tagMW,
                    discrepancy: item.discrepancy,
                },
            };
            acc.set(key, updated);
            return acc;
        }, new Map());

        return Array.from(formatted.values());
    }, []);

    const flattenData = useCallback((data) => {
        return data.reduce((acc, item) => {
            hours.forEach((hour) => {
                const hourData = item[hour] ?? {};
                const flattenedRowData = {
                    ...item,
                    ...hourData,
                    HE: hour,
                    scheduleMW: hourData.scheduleMW,
                    price: hourData.price,
                    dealMW: hourData.dealMW,
                    tagMW: hourData.tagMW,
                    Energy: hourData.Energy,
                    tagMW: hourData.tagMW,
                };
                if (!['dealMW', 'scheduleMW', 'tagMW', 'price',].every(key => isNullOrUndefined(hourData[key]))) {
                    acc.push(flattenedRowData);
                }
            });
            return acc;
        }, []);
    }, [hours]);

    const loadGridData = useCallback(async (formData, fetchFn, gridRef, getRowIdFn) => {
        const response = await fetchFn(formData);
        if (!response) return;
        const data = response?.data ?? [];
        applyData(gridRef, data, getRowIdFn);
        return response;
    }, [applyData]);

    const loadCounterpartyData = useCallback(async (formData) => {
        return loadGridData(formData, fetchCounterpartyData, counterpartyGridRef, getCounterpartyRowId);
    }, [fetchCounterpartyData, getCounterpartyRowId, loadGridData]);

    const loadDailyData = useCallback(async (formData) => {
        return loadGridData(formData, fetchDailyData, dailyGridRef, getDailyRowId);
    }, [fetchDailyData, getDailyRowId, loadGridData]);

    const silentlyLoadHourlyData = useCallback(async (formData) => {
        const selectedCounterpartyRow = counterpartyGridRef.current?.api?.getSelectedNodes()[0]?.data;
        const counterparty = selectedCounterpartyRow?.Counterparty;
        const transaction = selectedCounterpartyRow?.Transaction;
        if (!counterparty || !transaction) return; //can't fetch hourly data without a counterparty and transaction

        //need to fetch the hourly data for each date selected in the daily grid
        const selectedData = dailyGridRef.current?.api.getSelectedNodes().map(node => node.data) ?? [];
        const promises = selectedData.map(data => fetchHourlyData({ ...formData, ...data, date: data.date, counterparty, transaction, }));
        promises.forEach(promise => promise.then((response) => {
            if (!response) return;
            let data = response?.data ?? [];

            if (pivotHours) {
                data = rollUpData(data);
            }

            applyData(hourlyGridRef, data, getHourlyRowId);
        }));
    }, [applyData, fetchHourlyData, getHourlyRowId, pivotHours, rollUpData]);

    const loadHourlyData = useCallback(async (formData) => {
        hourlyGridRef.current?.api?.deselectAll();
        hourlyGridRef.current?.api?.showLoadingOverlay();

        const response = await fetchHourlyData(formData);
        if (!response) return;
        const data = response?.data ?? [];

        if (pivotHours) {
            const rolledUp = rollUpData(data);
            hourlyGridRef.current.api.applyTransaction({ add: rolledUp, });
        } else {
            hourlyGridRef.current.api.applyTransaction({ add: data, });
        }
        return response;
    }, [fetchHourlyData, pivotHours, rollUpData]);

    const clearDailyData = useCallback(() => {
        dailyGridRef.current?.api.setRowData([]);
    }, []);

    const clearHourlyData = useCallback(() => {
        hourlyGridRef.current?.api.setRowData([]);
    }, []);

    const removeHourlyDataForDate = useCallback((date) => {
        const toRemove = [];
        hourlyGridRef.current?.api.forEachLeafNode(node => {
            if (node.data['Flow Date'] === date) {
                toRemove.push(node.data);
            }
        });
        hourlyGridRef.current?.api.applyTransaction({ remove: toRemove });
    }, []);

    function calculatePanelMinHeight(id) {
        const accordionSummary = document.getElementById(id);
        const accordionSummaryHeight = accordionSummary?.clientHeight;
        return accordionSummaryHeight;
    }

    function calculatePanelCollapsedSize(id, panelGroupId) {
        const accordionSummary = document.getElementById(id);
        const accordionSummaryHeight = accordionSummary?.clientHeight;
        const panelGroup = document.getElementById(panelGroupId);
        const panelGroupHeight = panelGroup?.clientHeight;
        const topPanelCollapsedSize = (accordionSummaryHeight / panelGroupHeight) * 100;
        return topPanelCollapsedSize;
    }

    const hourlyContext = useMemo(() => ({
        ...view,
        layout: view.layout?.hourly,
    }), [view]);

    const dailyContext = useMemo(() => ({
        ...view,
        layout: view.layout?.daily,
    }), [view]);

    const counterpartyGridContext = useMemo(() => ({
        ...view,
        layout: view.layout?.counterparty,
    }), [view]);

    const clearDailySelections = useCallback(() => {
        dailyGridRef.current?.api.deselectAll();
        clearHourlyData();
    }, [clearHourlyData]);

    const applyDataBasedOnPivot = useCallback((pivot) => {
        const data = [];
        hourlyGridRef.current?.api.forEachLeafNode((node) => {
            data.push(node.data);
        });

        if (pivot) {
            const rolledUp = rollUpData(data);
            applyData(hourlyGridRef, rolledUp, getHourlyRowId);
        } else {
            const flattened = flattenData(data);
            applyData(hourlyGridRef, flattened, getHourlyRowId);
        }
    }, [applyData, getHourlyRowId, rollUpData, flattenData]);

    const handleCounterpartySelectionChanged = useCallback((data) => {
        if (data.counterparty && data.transaction) {
            clearDailySelections();
            loadDailyData(data);
        } else {
            clearDailyData();
            clearHourlyData();
        }
    }, [loadDailyData, clearDailyData, clearHourlyData, clearDailySelections]);

    const handleDailySelectionChanged = useCallback((data, isSelected) => {
        if (isSelected) {
            const selectedCounterpartyRow = counterpartyGridRef.current?.api.getSelectedNodes()[0]?.data;
            const fetchData = {
                ...data,
                counterparty: selectedCounterpartyRow.Counterparty,
                transaction: selectedCounterpartyRow.Transaction,
            };
            loadHourlyData(fetchData);
        } else {
            //cancel any pending requests for this date
            pendingRequestsRef.current[data.date]?.cancel();
            delete pendingRequestsRef.current[data.date];

            removeHourlyDataForDate(data.date);
        }
    }, [loadHourlyData, removeHourlyDataForDate]);

    const handleHourlySelectionChanged = useCallback((data) => {
        setSelectedHourlyData(data);
    }, []);

    const getBookoutData = useCallback(() => {
        if (!selectedHourlyData) {
            return null;
        }

        const dealId = selectedHourlyData.dealID;
        const flowDate = selectedHourlyData['Flow Date'];

        let rowData = [];
        hourlyGridRef.current?.api.forEachLeafNode(node => {
            const nodeData = node.data;
            if (nodeData['Flow Date'] === flowDate && nodeData.dealID === dealId) {
                rowData.push(nodeData);
            }
        });

        if (!pivotHours) {
            //make sure the row data is pivoted for consistency
            rowData = rollUpData(rowData);
        }

        const dealData = hours.reduce((acc, hour) => {
            const rowWithNonNullValue = rowData.find(row => !isNullOrUndefined(row[hour]?.dealMW))
            const dealMW = rowWithNonNullValue ? rowWithNonNullValue[hour]?.dealMW : 0;
            return {
                ...acc,
                [hour]: dealMW,
            };
        }, { id: dealId, dealName: selectedHourlyData['Deal Name'], });

        const dealPriceData = hours.reduce((acc, hour) => {
            const rowWithNonNullValue = rowData.find(row => !isNullOrUndefined(row[hour]?.price))
            const dealPrice = rowWithNonNullValue ? rowWithNonNullValue[hour]?.price : 0;
            return {
                ...acc,
                [hour]: dealPrice,
            };
        }, { id: 'Deal Price', });

        const dataWithSchedules = rowData.filter(row => row.scheduleID);

        const scheduleData = dataWithSchedules.map(row => {
            return hours.reduce((acc, hour) => {
                const scheduleMW = row[hour]?.scheduleMW ?? 0;
                return {
                    ...acc,
                    [hour]: scheduleMW,
                };
            }, { id: row.scheduleID, });
        });

        return {
            date: selectedHourlyData['Flow Date'],
            deal: dealData,
            price: dealPriceData,
            schedules: scheduleData,
        };
    }, [selectedHourlyData, pivotHours, rollUpData, hours]);

    const handlePivotHoursClick = e => {
        if (expandHourly) {
            e.stopPropagation();
        }
        setPivotHours(!pivotHours);
        applyDataBasedOnPivot(!pivotHours);
    }

    const summaryAccordionId = 'checkout-summary-accordion';
    const hourlyAccordionId = 'checkout-hourly-accordion';
    const verticalPanelGroupId = 'checkoutVerticalPanelGroup';
    const expandedLayoutRef = useRef();
    const expandedPanelRef = useRef([expandSummary, expandHourly,]);

    const saveAccordionState = useCallback((summaryExpanded, hourlyExpanded) => {
        localStorage.setItem(accordionStateKey, JSON.stringify([summaryExpanded, hourlyExpanded,]));
    }, []);

    const handleLayoutChange = useCallback((sizes) => {
        if (expandedPanelRef.current[0] && expandedPanelRef.current[1]) {
            expandedLayoutRef.current = sizes;
        }
    }, []);

    const handleUpdatePanelLayout = (topPanelExpanded, bottomPanelExpanded) => {
        expandedPanelRef.current = [topPanelExpanded, bottomPanelExpanded,];
        if (!topPanelExpanded && !bottomPanelExpanded) { //both are collapsed
            const topPanelCollapsedSize = calculatePanelCollapsedSize(summaryAccordionId, verticalPanelGroupId);
            panelGroupRef.current.setLayout([topPanelCollapsedSize, 100 - topPanelCollapsedSize]);
        } else if (topPanelExpanded && !bottomPanelExpanded) { //we are expanding the top accordion and the bottom is collapsed
            //if the bottom panel is collapsed, expand the top panel to full height
            const size = 100 - calculatePanelCollapsedSize(hourlyAccordionId, verticalPanelGroupId);
            panelGroupRef.current.setLayout([size, 100 - size]);
        } else if (!topPanelExpanded && bottomPanelExpanded) { //if the top panel is collapsed, expand the bottom panel to full height
            const size = 100 - calculatePanelCollapsedSize(summaryAccordionId, verticalPanelGroupId);
            panelGroupRef.current.setLayout([100 - size, size]);
        } else { //both are expanded, use the saved layout
            panelGroupRef.current.setLayout(expandedLayoutRef.current);
        }
    }

    useEffect(() => {
        expandedLayoutRef.current = panelGroupRef.current.getLayout();
        handleUpdatePanelLayout(expandSummary, expandHourly);
    }, []);

    const handleExpandSummary = () => {
        setExpandSummary(e => {
            saveAccordionState(!e, expandHourly);
            handleUpdatePanelLayout(!e, expandHourly);
            return !e;
        });
    }

    const handleExpandHourly = () => {
        setExpandHourly(e => {
            saveAccordionState(expandSummary, !e);
            handleUpdatePanelLayout(expandSummary, !e);
            return !e;
        });
    }

    function handleSavePanelLayout(key, value) {
        //only save the layout if both accordions are expanded
        //otherwise after refreshing the page, the layout will be incorrect
        if (expandSummary && expandHourly) {
            localStorage.setItem(key, value);
        }
    }

    const handleFlagStatusChanged = (scheduleId, toFlag, comments) => {
        //iterate over all grid data and update the Schedule_Status and Schedule_Comments fields for rows with the matching scheduleId
        const toUpdate = [];
        hourlyGridRef.current?.api.forEachLeafNode(node => {
            const data = node.data;
            if (data.scheduleID === scheduleId) {
                const newComment = toFlag ? 'FLAGGED: ' + comments : data.Schedule_Comment + ' CONFIRMED: ' + comments;
                const updatedData = {
                    ...data,
                    Schedule_Status: toFlag ? 'FLAGGED' : 'CONFIRMED',
                    Schedule_Comment: newComment,
                };
                toUpdate.push(updatedData);
            }
        });
        hourlyGridRef.current?.api.applyTransaction({ update: toUpdate });
    }

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={loadCounterpartyData}>
            <Stack sx={{ height: '100%' }} spacing={1}>
                <CheckoutRibbon
                    toolbarFormId={toolbarFormId}
                    handleRefresh={loadCounterpartyData}
                />

                <PanelGroup
                    autoSaveId='CheckoutVerticalPanelGroup'
                    direction='vertical'
                    id={verticalPanelGroupId}
                    ref={panelGroupRef}
                    onLayout={handleLayoutChange}
                    storage={{
                        getItem: (key) => localStorage.getItem(key),
                        setItem: handleSavePanelLayout,
                    }}
                >

                    <Panel
                        defaultSize={40}
                        className='flex-column'
                        id='checkout-summary-panel'
                        ref={topPanelRef}
                        style={{
                            minHeight: `${calculatePanelMinHeight(summaryAccordionId)}px`,
                        }}
                    >
                        <FlexAccordion
                            disableGutters
                            slots={{ transition: FlexCollapse }}
                            expanded={expandSummary}
                            onChange={handleExpandSummary}
                            elevation={4}
                            sx={{
                                borderRadius: '5px',
                            }}
                        >
                            <StyledAccordionSummary
                                expandIcon={<ExpandMore />}
                                id={summaryAccordionId}
                            >
                                <Typography>Summary</Typography>
                            </StyledAccordionSummary>
                            <AccordionDetails className='flex-column' sx={{ p: 0, }} >
                                <PanelGroup autoSaveId='CheckoutHorizontalPanelGroup' direction='horizontal' style={{ height: '100%', }}>
                                    <Panel defaultSize={50} style={{ height: '100%', }}>
                                        <CounterpartyGrid
                                            ref={counterpartyGridRef}
                                            context={counterpartyGridContext}
                                            getRowId={getCounterpartyRowId}
                                            onSelectionChanged={handleCounterpartySelectionChanged}
                                        />
                                    </Panel>

                                    <PanelResizeHandle
                                        style={{
                                            width: theme.spacing(1),
                                        }}
                                    />

                                    <Panel defaultSize={50}>
                                        <DailyGrid
                                            ref={dailyGridRef}
                                            context={dailyContext}
                                            getRowId={getDailyRowId}
                                            onSelectionChanged={handleDailySelectionChanged}
                                        />
                                    </Panel>
                                </PanelGroup>
                            </AccordionDetails>
                        </FlexAccordion>
                    </Panel>

                    <PanelResizeHandle
                        disabled={!expandSummary || !expandHourly}
                        style={{
                            height: theme.spacing(1),
                        }}
                    />
                    <Panel
                        defaultSize={60}
                        className='flex-column'
                        id='checkout-hourly-panel'
                        ref={bottomPanelRef}
                        style={{
                            minHeight: `${calculatePanelMinHeight(hourlyAccordionId)}px`,
                        }}
                    >
                        <FlexAccordion
                            disableGutters
                            slots={{ transition: FlexCollapse }}
                            expanded={expandHourly}
                            onChange={handleExpandHourly}
                            elevation={4}
                            sx={{
                                borderRadius: '5px',
                            }}
                        >
                            <StyledAccordionSummary
                                expandIcon={<ExpandMore />}
                                id={hourlyAccordionId}
                            >
                                <Typography sx={{ display: 'flex', alignItems: 'center', }}>Hourly</Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <HourlyToolbar
                                    pivot={pivotHours}
                                    handlePivotChange={handlePivotHoursClick}
                                    getBookoutData={getBookoutData}
                                    selectedData={selectedHourlyData}
                                    onFlagStatusChanged={handleFlagStatusChanged}
                                />
                            </StyledAccordionSummary>
                            <AccordionDetails className='flex-column' sx={{ p: 0, }}>
                                <HourlyGrid
                                    ref={hourlyGridRef}
                                    context={hourlyContext}
                                    getRowId={getHourlyRowId}
                                    pivotHours={pivotHours}
                                    setSelectedRow={handleHourlySelectionChanged}
                                    silentUpdate={silentlyLoadHourlyData}
                                />
                            </AccordionDetails>
                        </FlexAccordion>
                    </Panel>
                </PanelGroup>
            </Stack>
        </ViewContextProvider >
    )
});

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    minHeight: 0,
    [`& .MuiAccordionSummary-content`]: {
        margin: 0,
        padding: theme.spacing(0.5),
    },
}));

const FlexAccordion = styled(Accordion)(({ expanded }) => {
    return expanded
        ? {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            [`& .${accordionClasses.region}`]: {
                display: 'flex',
                height: '100%',
            },
            [`& .${accordionDetailsClasses.root}`]: {
                display: 'flex',
            },
        }
        : {
            [`& .${accordionClasses.region}`]: {
                height: 0,
            },
            [`& .${accordionDetailsClasses.root}`]: {
                display: 'none',
            },
        };
});

