import { Dialog, Slide, Stack, DialogContent, DialogTitle, TextField, Typography, Box, IconButton, DialogActions, Button, DialogContentText, Collapse, Autocomplete } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useApi } from "../../../useApi";
import { useEffect, useState, useMemo, useRef } from "react";
import dayjs from "dayjs";
import { apiUrlPrefix } from "../../../../authConfig";
import { PhysicalSegmentsPicker } from "./PhysicalSegmentsPicker";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import { AddTsrGrid } from "./AddTSRGrid";
import { useFormContext } from "react-hook-form";

export const AddTsrDialog = ({ open, handleSaveAndClose, handleCancel, selectedTag }) => {
    const { headers, get, } = useApi();
    const [tsrs, setTsrs] = useState([]);
    const [selectedTsrs, setSelectedTsrs] = useState([]);
    const [physicalSegmentOptions, setPhysicalSegmentOptions] = useState([]);
    const [physicalSegmentsForTsrs, setPhysicalSegmentsForTsrs] = useState({});
    const [productRef, setProductRef] = useState({ code: '' });
    const [showTsrGrid, setShowTsrGrid] = useState(true);
    const [productRefs, setProductRefs] = useState([]);
    const gridRef = useRef();
    const { watch, } = useFormContext();
    const timezone = watch('timezone');
    const book = watch('book');
    const tagCode = selectedTag?.tagCode;

    function handleNavigate() {
        if (showTsrGrid) { //move to the next step by toggling the grid visibility
            setShowTsrGrid(false);
        } else {
            handleSaveAndClose(productRef, selectedTsrs.map(tsr => ({
                ...tsr,
                physicalSegmentRef: physicalSegmentsForTsrs[tsr.ID],
            }))).then(() => {
                //reset the dialog state
                gridRef.current?.api?.deselectAll();
                setShowTsrGrid(true);
                setPhysicalSegmentsForTsrs({});
                setProductRef({ code: '' });
            });
        }
    }

    useEffect(() => {
        if (!!selectedTag && open) {
            fetchTSRs();
            fetchPhysicalSegments();
            fetchProductRefs();
        }
    }, [open]);

    async function fetchTSRs() {
        gridRef.current?.api?.showLoadingOverlay();
        const date = dayjs(selectedTag?.tagDate).format('MM/DD/YYYY');
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchTagTransmission_v1`
            + `&parm=${headers.userGuid}`
            + `&parm=${date}`
            + `&parm=${timezone}`
            + `&parm=${book ?? ''}`

        return get(url).then(response => {
            setTsrs(response?.data ?? []);
            setSelectedTsrs([]);
        }).finally(() => {
            gridRef.current?.api?.hideOverlay();
        });
    }

    async function fetchPhysicalSegments() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_Get_PhysicalSegmentsForTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedTag.tagIdx}`

        return get(url).then(response => {
            const newData = (response?.data ?? []).filter(segment => segment.POR && segment.POD);
            setPhysicalSegmentOptions(newData);
        });
    }

    async function fetchProductRefs() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.ui_fetch_etag_product_list`

        return get(url).then(response => {
            setProductRefs(response?.data ?? []);
        });
    }

    const allowSaveAndClose = !!productRef && Object.keys(physicalSegmentsForTsrs).length === selectedTsrs.length;

    return (
        <StyledDialog
            open={open}
            fullWidth
            maxWidth={showTsrGrid ? 'xl' : 'md'}
            keepMounted
        >
            <DialogTitle sx={{ p: 0 }}>
                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    {!showTsrGrid && <IconButton size='small' onClick={() => setShowTsrGrid(true)}>
                        <ArrowLeft />
                    </IconButton>}
                    <Typography variant='h6' sx={{ flexGrow: 1, }}>Add TSRs - Tag Code {tagCode}</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleCancel} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ p: 0, display: 'flex', flexDirection: 'column' }} >
                <Collapse in={showTsrGrid}>
                    <DialogContentText>
                        Please select the TSRs you would like to add to the selected tag.
                    </DialogContentText>
                    <AddTsrGrid
                        setSelectedTsrs={setSelectedTsrs}
                        data={tsrs}
                        ref={gridRef}
                    />
                </Collapse>
                <Collapse in={!showTsrGrid}>
                    <DialogContentText>
                        Please select a physical segment for each TSR, and a product ref for the tag.
                    </DialogContentText>
                    <PhysicalSegmentsPicker
                        segmentOptions={physicalSegmentOptions}
                        tsrList={selectedTsrs}
                        handleUpdateSegment={(tsrId, segment) => {
                            setPhysicalSegmentsForTsrs(prev => {
                                const next = { ...prev };
                                next[tsrId] = segment;
                                return next;
                            });
                        }}
                    />
                    <Box sx={{ pt: 3, pb: 1, display: 'flex', justifyContent: 'center' }}>
                        <Autocomplete
                            value={productRef}
                            options={productRefs}
                            onChange={(event, value) => {
                                setProductRef(value);
                            }}
                            getOptionLabel={(option) => option.code}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Product Ref"
                                    variant="outlined"
                                    sx={{ width: 200 }}
                                    size="small"
                                />
                            }
                        />
                    </Box>
                </Collapse>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', p: 0, pt: 2 }}>
                <Button onClick={handleCancel} color='error'>Cancel</Button>&nbsp;&nbsp;
                {!showTsrGrid && <Button onClick={() => setShowTsrGrid(true)} color='primary' variant='contained'>Back</Button>}
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    onMouseDown={handleNavigate}
                    color='primary'
                    variant='contained'
                    disabled={selectedTsrs.length === 0 || (!showTsrGrid && !allowSaveAndClose)}
                >{showTsrGrid ? 'Next' : 'Save & Close'}</Button>
            </DialogActions>
        </StyledDialog>
    );
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        padding: theme.spacing(2),
    },
}));
