import RateReviewIcon from '@mui/icons-material/RateReview';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { FlagScheduleDialog } from '../FlagScheduleDialog';
import { RibbonButton } from '../Ribbon';

export const FlagScheduleButton = ({ expanded, scheduleId, toFlag, onFlagStatusChanged, }) => {
    const [openFlagDialog, setOpenFlagDialog] = useState(false);

    const handleClose = (event) => {
        event.stopPropagation();
        setOpenFlagDialog(false);
    };

    const handleOpen = (event) => {
        event.stopPropagation();
        setOpenFlagDialog(true);
    }

    const label = toFlag ? 'Flag for Review' : 'Confirm';

    return (
        <>
            <FlagScheduleDialog
                open={openFlagDialog}
                closeDialog={handleClose}
                scheduleId={scheduleId}
                toFlag={toFlag}
                onFlagStatusChanged={onFlagStatusChanged}
            />
            <Tooltip title="Flag the selected schedule for review.">
                <span>
                    <RibbonButton
                        icon={<RateReviewIcon />}
                        onMouseDown={handleOpen}
                        color="primary"
                        variant='contained'
                        disabled={!scheduleId}
                        label={label}
                        expanded={expanded}
                    />
                </span>
            </Tooltip>
        </>
    );
};
