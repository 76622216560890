import { CheckoutView } from "./CheckoutView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { DashboardLazy } from "../Dashboard/DashboardLazy.js";

export const CheckoutDashboard = ({ visible, }) => {
    const dashboardKey = 'checkout-dashboard';
    const useShared = true;

    return (
        <DashboardLazy
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={CheckoutView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}
