import { useEffect, useState } from 'react';
import useHeader from '../useHeader';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { apiUrlPrefix } from '../../authConfig';
import { CriteriaContext } from './CriteriaContext';
import { requestTypes, statuses } from '../../utils/constants';

const CriteriaProvider = ({ children, application }) => {
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const [providers, setProviders] = useState([]);
    const [combinations, setCombinations] = useState([]);
    const [tsCombinations, setTsCombinations] = useState([]);
    const [customerCodes, setCustomerCodes] = useState([]);
    const [relatedRefs, setRelatedRefs] = useState([]);
    const [allPors, setAllPors] = useState([]);
    const [allPods, setAllPods] = useState([]);

    useEffect(() => {
        if (headers.userGuid) {
            fetchPorPodChoices();
            fetchTSCombinations();
            fetchCustomerCodes();
            fetchRelatedRefs();
            fetchAllPors();
            fetchAllPods();
        }
    }, [headers]);

    async function fetchCustomerCodes() {
        const options = {
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_CustomerCodeChoiceFetch`,
        }

        axios(options).then(response => {
            setCustomerCodes(response.data.map(entry => entry.customerCode));
        }).catch(error => {
            enqueueSnackbar(`Error fetching customer codes. Message: ${error}`)
        });
    }

    async function fetchRelatedRefs() {
        const options = {
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_RelatedRefCombosFetch&parm=${headers.userGuid}`,
        }

        axios(options).then(response => {
            setRelatedRefs(response.data);
        }).catch(error => {
            enqueueSnackbar(`Error fetching related refs. Message: ${error}`)
        });
    }


    async function fetchTSCombinations() {
        const options = {
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchTSCombinations`,
        }

        axios(options).then(response => {
            setTsCombinations(response.data);
        }).catch(error => {
            enqueueSnackbar(`Error fetching TS combinations. Message: ${error}`)
        });
    }

    async function fetchPorPodChoices() {
        const options = {
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchAllPorPodCombinations_v2`,
        }

        axios(options).then(response => {
            setCombinations(response.data);
            setProviders([...new Set(response.data.map(combo => combo.Provider))]);
        }).catch(error => {
            enqueueSnackbar(`Error fetching provider choices. Message: ${error}`)
        });
    }

    async function fetchAllPors() {
        const options = {
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_porChoiceFetch`,
        }

        axios(options).then(response => {
            setAllPors(response.data.map(p => p.por));
        }).catch(error => {
            enqueueSnackbar(`Error fetching POR choices. Message: ${error}`)
        });
    }

    async function fetchAllPods() {
        const options = {
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_podChoiceFetch`,
        }

        axios(options).then(response => {
            setAllPods(response.data.map(p => p.pod));
        }).catch(error => {
            enqueueSnackbar(`Error fetching POD choices. Message: ${error}`)
        });
    }

    const data = {
        providers: providers,
        combinations: combinations,
        tsCombinations: tsCombinations,
        customerCodes: customerCodes,
        relatedRefs: relatedRefs,
        requestTypes: requestTypes,
        statuses: statuses,
        pors: allPors,
        pods: allPods,
        application,
    }

    return (
        <CriteriaContext.Provider value={data}>
            {children}
        </CriteriaContext.Provider>
    )
}

export default CriteriaProvider;
