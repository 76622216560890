import { FormGroup, FormControlLabel, Box, Switch, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

export const PaletteToolPanel = ({ gridRef, paletteRef, storageKey, containerId }) => {
	const savedSettings = JSON.parse(localStorage.getItem(storageKey)) ?? {};
	const [showHeatmap, setShowHeatmap] = useState(savedSettings.showHeatmap ?? false);
	const [showBorders, setShowBorders] = useState(savedSettings.showBorders ?? false);

	useEffect(() => {
		paletteRef.current = { showHeatmap, showBorders };
		handleToggleBorders(showBorders);
	}, []);

	function handleToggleHeatmap() {
		setShowHeatmap(!showHeatmap);
		//eventually we should use the grid context instead of a separate ref
		paletteRef.current = { ...paletteRef.current, showHeatmap: !showHeatmap };
		gridRef.current.api.refreshCells({ force: true, });

		//update local storage
		const currentlySaved = JSON.parse(localStorage.getItem(storageKey)) ?? {};
		localStorage.setItem(storageKey, JSON.stringify({ ...currentlySaved, showHeatmap: !showHeatmap }));
	}

	function handleToggleBorders(toShow) {
		//use the container id to get the container div
		//toggle the ag-grid-column-borders class to show/hide borders
		const container = document.getElementById(containerId);
		if (container) {
			if (toShow) {
				container.classList.add('ag-grid-column-borders');
			} else {
				container.classList.remove('ag-grid-column-borders');
			}
		}

		setShowBorders(toShow);
		paletteRef.current = { ...paletteRef.current, showBorders: toShow, };

		//update local storage
		const currentlySaved = JSON.parse(localStorage.getItem(storageKey)) ?? {};
		localStorage.setItem(storageKey, JSON.stringify({ ...currentlySaved, showBorders: toShow, }));
	}

	return (
		<Box sx={{ display: 'flex', p: 1, flexDirection: 'column' }}>
			<Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', p: 1, }}>
				<Typography variant='h6'>Cell Palette</Typography>
			</Box>

			<Stack sx={{ width: '100%', p: 1, }} spacing={2}>
				<FormGroup>
					<FormControlLabel
						label='Show Heatmap'
						control={
							<Switch
								checked={showHeatmap}
								onMouseDown={handleToggleHeatmap}
							/>
						}
					/>
				</FormGroup>

				{containerId && <FormGroup>
					<FormControlLabel
						label='Show Borders'
						control={
							<Switch
								checked={showBorders}
								onMouseDown={() => handleToggleBorders(!showBorders)}
							/>
						}
					/>
				</FormGroup>}
			</Stack>
		</Box>
	);
};
