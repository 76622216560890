import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useState } from 'react';
import { useFormContext } from "react-hook-form";
import { RibbonGroupContainer } from "../../Ribbon";
import { RefreshButton } from "../../RibbonButtons";

export const GridActionsGroup = ({ formId, expanded, handleRefresh, filterGroups, setFilterGroups, pivotHours, setPivotHours, toggleMWColumns, }) => {
    const { getValues, watch } = useFormContext();
    const [showScheduleMW, setShowScheduleMW] = useState(!!getValues('showScheduleMW'));
    const [showDealMW, setShowDealMW] = useState(!!getValues('showDealMW'));
    const [showTagMW, setShowTagMW] = useState(!!getValues('showTagMW'));
    const startDate = watch('startDate');
    const stopDate = watch('stopDate');

    const handleToggleShowScheduleMW = () => {
        setShowScheduleMW(!showScheduleMW);
        toggleMWColumns('scheduleMW', !showScheduleMW, startDate, stopDate);
    }

    const handleToggleShowDealMW = () => {
        setShowDealMW(!showDealMW);
        toggleMWColumns('dealMW', !showDealMW, startDate, stopDate);
    }

    const handleToggleShowTagMW = () => {
        setShowTagMW(!showTagMW);
        toggleMWColumns('tagMW', !showTagMW, startDate, stopDate);
    }

    return (
        <RibbonGroupContainer>

            <RefreshButton
                form={formId}
                expanded={expanded}
                onRefresh={handleRefresh}
            />

            <Stack>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filterGroups}
                            onClick={() => setFilterGroups(f => !f)}
                            sx={{ py: 0, }}
                        />
                    }
                    label="Filter Groups"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={pivotHours}
                            onClick={() => setPivotHours(p => !p)}
                            sx={{ py: 0, }}
                        />
                    }
                    label="Pivot Hours"
                />
            </Stack>

            <Stack >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showScheduleMW}
                            onClick={handleToggleShowScheduleMW}
                            sx={{ py: 0, }}
                        />
                    }
                    label="Show Schedule MW"
                />


                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showDealMW}
                            onClick={handleToggleShowDealMW}
                            sx={{ py: 0, }}
                        />
                    }
                    label="Show Deal MW"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showTagMW}
                            onClick={handleToggleShowTagMW}
                            sx={{ py: 0, }}
                        />
                    }
                    label="Show Tag MW"
                />
            </Stack>


        </RibbonGroupContainer>
    )
}
