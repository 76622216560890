import { Box, useTheme, IconButton, } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export const Toolbar = ({ date, onDateChange, disabled, handleFetchPrevious, handleFetchNext, }) => {
	const theme = useTheme();

	return (
		<Box>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<IconButton size='small' disabled={disabled} onMouseDown={() => {
					handleFetchPrevious();
				}}>
					<ArrowLeftIcon />
				</IconButton>                <DateTimePicker
					value={date}
					disabled={disabled}
					label='Date'
					onChange={(newDate) => {
						onDateChange(newDate);
					}}
					ampm={false}
					slotProps={{
						textField: {
							value: date,
							size: 'small',
						},
						layout: {
							sx: {
								ul: {
									'::-webkit-scrollbar': {
										width: '10px',
									},
									'::-webkit-scrollbar-thumb': {
										backgroundColor: theme.palette.primary.dark,
										borderRadius: '20px',
										border: '3px solid transparent',
										backgroundClip: 'padding-box',
									},
									'::-webkit-scrollbar-track': {
										background: 'transparent',
									},
									'::-webkit-scrollbar-thumb:hover': {
										border: 0,
									},
								},
							},
						}
					}}
				/>
				<IconButton size='small' disabled={disabled} onMouseDown={() => {
					handleFetchNext();
				}}>
					<ArrowRightIcon />
				</IconButton>
			</LocalizationProvider>
		</Box>
	);
}

