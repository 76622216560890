import { AgChartsReact } from 'ag-charts-react';
import { useMemo, } from 'react';
import { useTheme } from '@mui/material';

export const AnalysisChart = () => {
    const deals = [];
    const theme = useTheme();
    const palette = theme.palette.primary;

    const purchases = deals.filter(deal => deal.Transaction_Type === 'Purchase');
    const sales = deals.filter(deal => deal.Transaction_Type === 'Sale');

    //create a Map with the dealID as key and the stackGroup as value so we can stack the purchases and sales
    const stackGroupMapByDealId = useMemo(() => {
        const stackGroupMap = new Map();
        purchases.forEach(purchase => {
            stackGroupMap.set(purchase.dealID, 'Purchases');
        });
        sales.forEach(sale => {
            stackGroupMap.set(sale.dealID, 'Sales');
        });
        return stackGroupMap;
    }, [purchases, sales]);

    function formatDataForChart(data) {
        //create a Map with the hour as key
        const dataMap = new Map(Array.from({ length: 26 }, (_, i) => i).map(i => [i + 1, { HE: i + 1, }]));
        //now for every deal, add the MW for each hour
        data.forEach(deal => {
            const id = deal.dealID.toString();
            Array.from({ length: 26 }, (_, i) => i).forEach(i => {
                const hour = i + 1;
                dataMap.get(hour)[id] = deal[hour] ?? 0;
            });
        });
        return Array.from(dataMap.values());
    }

    function tooltipRenderer({ datum, xKey, yKey }) {
        const HE = datum[xKey];
        const MW = datum[yKey];
        return {
            content: `HE ${HE}: ${MW} MW`,
        };
    }

    const chartOptions = useMemo(() => ({
        theme: {
            baseTheme: theme.palette.mode === 'light' ? 'ag-material' : 'ag-material-dark',
            palette: {
                fills: [
                    palette.yellow,
                    palette.main,
                    palette.red,
                    palette.green,
                    palette.orange,
                    palette.dark,
                ],
                strokes: [
                    palette.yellow,
                    palette.main,
                    palette.red,
                    palette.green,
                    palette.orange,
                    palette.dark,
                ],
            },
            overrides: {
                line: { series: { strokeWidth: 3, marker: { enabled: true } } },
            },
        },
        background: {
            fill: theme.palette.mode === 'dark' ? palette.darkBackground : palette.white,
        },
        autoSize: true,
        padding: {
            left: 40,
            right: 40,
        },
        axes: [
            {
                position: 'bottom',
                type: 'number',
                nice: false,
                label: {
                    formatter: ({ value, }) => `HE ${value}`,
                },
            },
            {
                position: 'left',
                type: 'number',
                min: 0,
                title: {
                    text: 'MW',
                },
            },
        ],
        legend: {
            position: 'right',
        },
        data: formatDataForChart(deals),
        series: [
            ...Array.from(stackGroupMapByDealId.keys()).map(dealID => ({
                yKey: dealID.toString(),
                yName: dealID.toString(),
                type: 'bar',
                xKey: 'HE',
                stacked: true,
                stackGroup: stackGroupMapByDealId.get(dealID),
                tooltip: { renderer: tooltipRenderer, },
            })),
        ],
    }), [stackGroupMapByDealId, theme.palette, deals]);

    return (
        <AgChartsReact options={chartOptions} />
    );
};
